import axios from 'axios';
import parseModelStateErrors from '../utils/parseModelStateErrors';
import localStorageService from './localStorageService';

const instance = axios.create();

instance.defaults.headers.common.Accept = 'application/json';
instance.defaults.headers.common['Content-Type'] = 'application/json';

instance.interceptors.request.use(function (config) {
    const authToken = localStorageService.getAuthToken();
    if (authToken) config.headers.Authorization = `Bearer ${authToken}`;

    const accessToken = localStorageService.getAccessToken();
    if (accessToken) config.headers['x-auth'] = accessToken;

    return config;
},
    function (error) {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    function (response) {
        return response;
    },
    function (error) {
        if (error.isCanceled) {
            return Promise.reject(error);
        }
        else if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx

            if (error.response.status === 500) {
                return Promise.reject([
                    'An unexpected error has occurred, if the problem persists please contact your system administrator.',
                ]);
            }

            if (error.response.status === 401) {

                if (error.response.headers['www-authenticate'] && error.response.headers['www-authenticate'].includes('Bearer error="invalid_token"')) {
                    localStorageService.clear();
                    window.location.href = window.origin;
                    return Promise.reject(['Your login has expired, please wait while you are redirected back to the login page...']);
                }

                return Promise.reject([
                    'You do not have permission to access the requested resource.',
                ]);

            }

            const { errorNumber } = error.response.data;

            // expired/invalid token
            if (errorNumber === 102 || errorNumber === 103) {
                localStorageService.clear();
                window.location.href = window.origin;
                return Promise.reject([
                    'Your login has expired, please wait while you are redirected back to the login page...',
                ]);
            }

            return Promise.reject(parseModelStateErrors(error.response.data));
        } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            return Promise.reject([
                'The server could not be reached, please try again later.',
            ]);
        } else {
            // Something happened in setting up the request that triggered an Error
            return Promise.reject(['Invalid request']);
        }
    },
);

export { instance };
