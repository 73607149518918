import React from 'react';
import usePasswordPolicy from './usePasswordPolicy';

const PasswordPolicyFeedback: React.FC = () => {
  const { feedback } = usePasswordPolicy();

  return (
    <ul className="list-unstyled">
      {feedback.map(item => (
        <li key={item.text}>
          {item.isValid ? (
            <i className="far fa-check-square fa-sm text-success mr-2" />
          ) : (
            <i className="far fa-square fa-sm text-danger mr-2" />
          )}
          <small>{item.text}</small>
        </li>
      ))}
    </ul>
  );
};

export default PasswordPolicyFeedback;
