import storage from 'local-storage-fallback';
import { IDevice } from '../components/deviceSettings/form';

const AuthTokenKey = 'auth_token';
const AccessTokenKey = 'access_token';
const Device = 'device';
// TO - 22/08/2023
const SelectedContact = 'selected_contact';
const SelectedOrg = 'selected_org';

export default {
  getAuthToken: () => storage.getItem(AuthTokenKey),
  getAccessToken: () => storage.getItem(AccessTokenKey),
  getDevice: (): IDevice | null => {
    const device = storage.getItem(Device);

    if (device === null) {
      return null;
    }

    return JSON.parse(device);
  },
  setTokens: (authToken: string, accessToken: string) => {
    storage.setItem(AuthTokenKey, authToken);
    storage.setItem(AccessTokenKey, accessToken);
  },
  setDevice: (device: IDevice): void => {
    storage.setItem(Device, JSON.stringify(device));
  },
  clearDevice: () => storage.removeItem(Device),
  clear: () => {
    storage.removeItem(AuthTokenKey);
    storage.removeItem(AccessTokenKey);
      storage.removeItem(Device);
      // TO - 22/08/2023
      storage.removeItem(SelectedContact);
      storage.removeItem(SelectedOrg);
    },
    // TO - 22/08/2023
    getSelectedContact: () => storage.getItem(SelectedContact),
    getSelectedOrg: () => storage.getItem(SelectedOrg),
    setSelectedContact: (contactId: string) => storage.setItem(SelectedContact, contactId),
    setSelectedOrg: (orgId: string) => storage.setItem(SelectedOrg, orgId)
};
