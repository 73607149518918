import React from 'react';
import { INotificationModalProps } from './types';
import Modal from 'react-modal';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { FormHeading } from '../../forms/formHeading';

export const NotificationModal: React.FC<INotificationModalProps> = ({
  title,
  message,
  onRequestClose,
}) => {
  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <FormHeading heading={title} />
      <p>{message}</p>
      <ButtonComponent onClick={onRequestClose}>OK</ButtonComponent>
    </Modal>
  );
};
