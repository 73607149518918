import React, { useEffect, useState } from 'react';
import { DropDownItem } from '../types';
import UserDataContext from './UserDataContext';
import useAuthentication from '../authentication';
import localStorageService from '../../services/localStorageService';

const UserDataProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useAuthentication();

    const [selectedContactFileId, setSelectedContactFileId] = useState<number>(0);
    const [selectedOrganisationFileId, setSelectedOrganisationFileId] = useState<
        number
    >(0);
    const [contacts, setContacts] = useState<Array<DropDownItem>>([]);
    const [organisations, setOrganisations] = useState<Array<DropDownItem>>([]);

    useEffect(() => {
        if (typeof user === 'undefined') {
            setContacts([]);
            setSelectedContactFileId(0);
            setOrganisations([]);
            setSelectedOrganisationFileId(0);
        } else {
            // update the list of contacts
            setContacts(
                user.contacts.map(item => ({
                    id: item.fileId.toString(),
                    displayValue: item.name,
                })),
            );

            // default to first contact - TO - 22/08/2023
            const defaultContact = localStorageService.getSelectedContact();

            if (defaultContact !== null) {
                setSelectedContactFileId(parseInt(defaultContact));
            } else {
                setSelectedContactFileId(user.contacts[0]?.fileId || 0);
            }
        }
    }, [user]);

    useEffect(() => {
        if (typeof user !== 'undefined') {
            const contactData = user.contacts.find(
                item => item.fileId === selectedContactFileId,
            );

            if (typeof contactData === 'undefined') {
                setOrganisations([]);
            } else {
                const organisations = contactData.organisations;

                // update the list of organisations
                setOrganisations(
                    organisations.map(item => ({
                        id: item.fileId.toString(),
                        displayValue: item.name,
                    })),
                );

                // default to the first organisation - TO - 22/08/2023
                const defaultOrg = localStorageService.getSelectedOrg();

                if (defaultOrg !== null) {
                    setSelectedOrganisationFileId(parseInt(defaultOrg));
                } else {
                    setSelectedOrganisationFileId(organisations[0]?.fileId || 0);
                }
            }
        }
    }, [user, selectedContactFileId]);

    return (
        <UserDataContext.Provider
            value={{
                contacts,
                organisations,
                selectedContactFileId,
                selectedOrganisationFileId,
                getSelectedContactName: () =>
                    contacts.find(
                        contact => contact.id === selectedContactFileId.toString(),
                    )?.displayValue ?? '[Unknown]',
                getSelectedOrganisationName: () =>
                    organisations.find(
                        organisation =>
                            organisation.id === selectedOrganisationFileId.toString(),
                    )?.displayValue ?? '[Unknown]',
                onChangeContact: (id: number) => setSelectedContactFileId(id),
                onChangeOrganisation: (id: number) => setSelectedOrganisationFileId(id),
            }}
        >
            {children}
        </UserDataContext.Provider>
    );
};

export default UserDataProvider;
