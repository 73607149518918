import React, { useState } from "react";
import { IAddGroupLinkModelProps } from "../../types";
import Modal from 'react-modal';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { AddId } from "../../groupLinkGridToolbar";
import ErrorList from "../../../../../errorList";
import { FormGroup, FormHeading, SubmitButton } from "../../../../../forms";
import { Form, Formik } from "formik";
import addGroupLink from "./addGroupLink";
import { useDataFetcher } from "../../../../../hooks";
import AjaxErrorBoundary from "../../../../../ajax/errorBoundary";
import { ILocalGroupLink } from "../../../../../settings/localGroupLinkMaintenance/types";
import { DropDownList } from "../../../../../formikBindings";

const localGroupLinksTransformer = (localGroupLinks: Array<ILocalGroupLink>): Array<{ [key: string]: Object }> => {
  return localGroupLinks.map(localGroupLink => ({ 
    id: localGroupLink.fileId,
    item: {
      id: localGroupLink.fileId,
      displayValue: localGroupLink.description
    }
  }));
}

const AddGroupLinkModel: React.FC<IAddGroupLinkModelProps> = ({ contactFileId, groupId, setShowModel, handleComplete }) => {
  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);

  const {
    busy,
    errors,
    data
  } = useDataFetcher(`api/localGroupLinks/${groupId}`);

  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <AjaxErrorBoundary
        busy={busy}
        errors={errors}
        loadingText="Loading Local Group Links"
      >
        <FormHeading heading="Add Contact at Local Group Link" />
        <Formik
          initialValues={{ contactFileId: contactFileId, groupId: groupId, localGroupLinkFileId: 0 }}
          onSubmit={(values, { setSubmitting }) => {
            addGroupLink(values)
              .then(() => handleComplete(AddId))
              .catch(errors => setAjaxErrors(errors))
              .finally(() => setSubmitting(false));
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <ErrorList errors={ajaxErrors} />
              <fieldset disabled={isSubmitting}>
                <FormGroup name="localGroupLinkFileId" label="Local Group Link" required>
                  <DropDownList name="localGroupLinkFileId" dataSource={localGroupLinksTransformer(data)} />
                </FormGroup>
              </fieldset>
              <div className="d-flex">
                <ButtonComponent type="button" cssClass="e-secondary mr-2" onClick={() => setShowModel(false)}>
                  Cancel
                </ButtonComponent>
                <SubmitButton>Save</SubmitButton>
              </div>
            </Form>
          )}
        </Formik>
      </AjaxErrorBoundary>
    </Modal>
  )
}

export default AddGroupLinkModel;
