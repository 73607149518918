import qs from 'qs';

export default class queryString {
  static parse(value: string) {
    return qs.parse(value, { ignoreQueryPrefix: true });
  }

  static stringify(value: any) {
    return qs.stringify(value, {
      strictNullHandling: true,
      encodeValuesOnly: true,
      allowDots: true,
    });
  }
}
