import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDataFetcher } from '../../hooks';
import EditLocalGroupMemberDetailsPage from '../../localGroupMembers/member/edit';
import useUserData from '../../userData';
import { IMemberDetailFullParams } from './types';

const MemberDetailFull: React.FC = () => {
    
    const { id, localGroupFileId } = useParams();

    const [isAllowed, setIsAllowed] = useState<boolean>(false);
    const [groupId, setGroupId] = useState<number>(0);
    const [memberFileId, setMemberFileId] = useState<number>(0);
    const url = useMemo(() => `/api/dashboard/membercheck/${localGroupFileId}/${id}`, [localGroupFileId, id]);

    const { busy, errors, data } = useDataFetcher(
        url,
      );
    
    useEffect(() => {
        if (data) {
            setIsAllowed(data.isAllowed);
            setGroupId(data.groupId);
            setMemberFileId(data.memberId);
        }
      }, [data, localGroupFileId]);

    return (
        <div className="container">
            <div className="my-4">
                {(isAllowed) && (
                    <EditLocalGroupMemberDetailsPage
                        forNotification={false}
                        groupId={groupId}
                        fileId={memberFileId}
                        onClose={() => { window.close();}} />
                )}

                {(!isAllowed) && (
                    <div className="alert alert-danger" role="alert">
                            No permission to view selected reocrd.
                    </div>
                )}
            
            </div>
        </div>
    );
};

export default MemberDetailFull