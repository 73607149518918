import React, { useRef, useState } from 'react';
import ErrorList from '../../../errorList';
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Sort,
  Toolbar,
} from '@syncfusion/ej2-react-grids';
import { RowSelectEventArgs } from '@syncfusion/ej2-grids';
import {
  AddLinkModal,
  DeleteLinkConfirmationModal,
  DeleteLinkModal,
} from './modals';
import { ILink } from '../../../myDetails/form';
import { ILinksTabProps, IDeleteLinkState } from './types';
import useUserData from '../../../userData';
import reloadLinks from '../edit/reloadLinks';

const AddLinkButtonId: string = 'AddLink';
const DeleteLinkButtonId: string = 'DeleteLink';

const initialDeleteLinkState: IDeleteLinkState = {
  active: false,
  confirmed: false,
};

const LinksTab: React.FC<ILinksTabProps> = ({
    fileId,
    groupId,
  contactId,
  name,
  initialValues,
}) => {
  const gridElement = useRef<GridComponent>(null);

  const { selectedOrganisationFileId } = useUserData();

  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
  const [links, setLinks] = useState<Array<ILink>>(initialValues);
  const [selectedLink, setSelectedLink] = useState<ILink | null>(null);

  const [addingLink, setAddingLink] = useState<boolean>(false);
  const [deleteLinkState, setDeleteLinkState] = useState<IDeleteLinkState>(
    initialDeleteLinkState,
  );

  const handleRowClick = (args: RowSelectEventArgs | undefined) => {
    if (typeof args === 'undefined' || !args.data) return;

    const link = args.data as ILink;

    setSelectedLink(link);
  };

  const handleToolbarButtonClick = (args: any) => {
    if (!gridElement) return;

    if (args.item.id === AddLinkButtonId) {
      setAddingLink(true);
    } else if (args.item.id === DeleteLinkButtonId && selectedLink !== null) {
      setDeleteLinkState({ ...initialDeleteLinkState, active: true });
    }
  };

  const reloadData = () => {
    setAjaxErrors([]);
    setSelectedLink(null);

    reloadLinks(fileId, groupId)
      .then(response => setLinks(response.data))
      .catch(setAjaxErrors);
  };

  const handleRoleAdded = (): void => {
    setAddingLink(false);
    reloadData();
  };

  const handleRowDeleted = (): void => {
    setDeleteLinkState(initialDeleteLinkState);
    reloadData();
  };

  return (
    <div>
      <ErrorList errors={ajaxErrors} />
      <GridComponent
        ref={gridElement}
        className="my-3"
        dataSource={links}
        allowSorting
        allowFiltering
        filterSettings={{
          mode: 'Immediate',
          immediateModeDelay: 100,
        }}
        toolbar={[
          {
            text: 'Add',
            tooltipText: 'Add',
            prefixIcon: 'e-add',
            id: AddLinkButtonId,
          },
          {
            text: 'Delete',
            tooltipText: 'Delete',
            prefixIcon: 'e-delete',
            id: DeleteLinkButtonId,
          },
        ]}
        toolbarClick={handleToolbarButtonClick}
        rowSelected={handleRowClick}
      >
        <ColumnsDirective>
          <ColumnDirective field="fileId" headerText="fileId" visible={false} />
          <ColumnDirective
            field="addressFileId"
            headerText="addressFileId"
            visible={false}
          />
          <ColumnDirective field="id" headerText="Id" />
          <ColumnDirective field="name" headerText="Name" />
          <ColumnDirective
            field="typeDescId"
            headerText="typeDescId"
            visible={false}
          />
          <ColumnDirective field="type" headerText="Type" />
        </ColumnsDirective>
        <Inject services={[Sort, Filter, Toolbar]} />
      </GridComponent>

      {addingLink && (
        <AddLinkModal
          contactFileId={fileId}
          organisationFileId={selectedOrganisationFileId}
          onAdded={handleRoleAdded}
          onCancel={() => setAddingLink(false)}
        />
      )}
      {selectedLink &&
        deleteLinkState.active &&
        (deleteLinkState.confirmed ? (
          <DeleteLinkModal
            addressFileId={selectedLink.addressFileId}
            groupRoleDescId={selectedLink.typeDescId}
            contactId={contactId}
            name={name}
            onCancel={() => setDeleteLinkState(initialDeleteLinkState)}
            onDelete={handleRowDeleted}
          />
        ) : (
          <DeleteLinkConfirmationModal
            hasPermission={selectedOrganisationFileId === selectedLink.fileId}
            onConfirm={() =>
              setDeleteLinkState({ ...deleteLinkState, confirmed: true })
            }
            onCancel={() => setDeleteLinkState(initialDeleteLinkState)}
            contactLinksCount={links.length}
          />
        ))}
    </div>
  );
};

export default LinksTab;
