import React from 'react';
import { IErrorBoundaryState } from './types';
import UnexpectedErrorPage from '../routes/unexpectedError';

class ErrorBoundary extends React.Component<any, IErrorBoundaryState> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: Object) {
    this.setState({ error });
  }

  render() {
    if (this.state.error === null) {
      return this.props.children;
    }

    return <UnexpectedErrorPage />;
  }
}

export default ErrorBoundary;
