import { useRef } from 'react'

//https://github.com/antonioru/beautiful-react-hooks/blob/master/src/useIsFirstRender.ts

export const useIsFirstRender = () => {
  const isFirstRenderRef = useRef(true)

  if (isFirstRenderRef.current) {
    isFirstRenderRef.current = false

    return true
  }

  return isFirstRenderRef.current
}
