import { useContext } from 'react';
import { IAuthenticationContext } from './types';
import AuthenticationContext from './AuthenticationContext';

const useAuthentication = (): IAuthenticationContext => {
  const context = useContext(AuthenticationContext);

  if (!context) {
    throw new Error('This component must be a <AuthenticationProvider> component');
  }

  return context;
};

export default useAuthentication;
