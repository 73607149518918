import React, { useState } from "react";
import { IDeleteGroupLinkModelProps } from "../../types";
import Modal from 'react-modal';
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import deleteGroupLink from "./deleteGroupLink";
import { DeleteId } from "../../groupLinkGridToolbar";
import ErrorList from "../../../../../errorList";

const DeleteGroupLinkModel: React.FC<IDeleteGroupLinkModelProps> = ({ localGroupLinkFileId, setShowModel, handleComplete }) => {
  const [busy, setBusy] = useState<boolean>(false);
  const [errors, setErrors] = useState<Array<string>>([]);

  const handleDeletion = () => {
    setBusy(true);
    
    deleteGroupLink(localGroupLinkFileId)
      .then(() => handleComplete(DeleteId))
      .catch((errors) => setErrors(errors))
      .finally(() => setBusy(false));
  }

  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <ErrorList errors={errors} className="mb-4" />
      <p className="mb-4">Are you sure you want to delete this contact at local group link.</p>
      <ButtonComponent type="button" cssClass="e-secondary mr-2" onClick={() => setShowModel(false)} disabled={busy}>
        Cancel
      </ButtonComponent>
      <ButtonComponent type="button" cssClass="e-primary" onClick={() => handleDeletion()} disabled={busy}>
        Delete
      </ButtonComponent>
    </Modal>
  )
}

export default DeleteGroupLinkModel;
