import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import styles from './UnexpectedErrorPage.module.css';

const UnexpectedErrorPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <div className={styles.container}>
            <p className={styles.heading}>UNEXPECTED ERROR</p>
            <p>
                An unexpected error has occurred, if the problem persists please contact your system administrator.
            </p>
            <ButtonComponent
                className="e-primary"
                onClick={() => navigate('/', { replace: true })}
            >
                Back to Home Page
            </ButtonComponent>
        </div>
    );
};

export default UnexpectedErrorPage;
