import React from 'react';
import { Navigate, RouteProps } from 'react-router-dom';
import useAuthentication from '../../authentication';

const RequireAuth: React.FC<RouteProps> = ({
    children
}) => {
    const { isAuthenticated, passwordResetRequired } = useAuthentication();

    return (
        <>
            {isAuthenticated ? (
                <>
                    {passwordResetRequired ? (
                        <Navigate to="/resetPassword/current" />
                    ) : (
                        <>{children}</>
                    )}
                </>
            ) : (
                <Navigate to="/login" />
            )}
        </>
    );
};

export default RequireAuth;
