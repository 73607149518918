import { useEffect, useState } from 'react';
import axios from 'axios';
import { instance } from '../../services/api';
import makeCancelable from '../../utils/makeCancelable';

const initialState = {
  busy: true,
  data: [],
  errors: [],
};

export const useBatchDataFetcher = (
  urls: Array<string>,
  config?: {
    transformers: Array<(data: any) => any>;
    finalTransformation?: (data: Array<any>) => any;
  },
): { busy: boolean; data: Array<any>; errors: Array<string> } => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const source = axios.CancelToken.source();

    setState(initialState);

    const cancelableRequest = makeCancelable(
      axios.all(
        urls.map(url =>
          instance.get(url, {
            cancelToken: source.token,
          }),
        ),
      ),
      source,
    );

    const applyTransformations = (data: Array<any>): any => {
      if (!config) return data;

      const transformedData = data.map((r: any, index: number) =>
        transformData(r, index),
      );

      return config.finalTransformation
        ? config.finalTransformation(transformedData)
        : transformedData;
    };

    const transformData = (data: any, index: number): any => {
      if (!config || !config.transformers[index]) return data;

      return config.transformers[index](data);
    };

    cancelableRequest.promise
      .then(response =>
        setState({
          busy: false,
          data: applyTransformations(response.map((r: any) => r.data)),
          errors: [],
        }),
      )
      .catch(errors => {
        if (!errors.isCanceled) {
          setState({ busy: false, data: [], errors });
        }
      });

    return () => {
      cancelableRequest.cancel();
    };
  }, [urls, config]);

  return {
    busy: state.busy,
    data: state.data,
    errors: state.errors,
  };
};
