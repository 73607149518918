import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ActionButton, FormGroup, FormHeading } from '../forms';
import ErrorList from '../errorList';
import requestPasswordReset from './requestPasswordReset';
import { TextBox } from '../formikBindings';
import { NotificationModal } from '../modals';
import schema from './schema';

const ForgottenPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);

    return (
        <>
            <div className="container">
                <Formik
                    initialValues={{ userName: '' }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setAjaxErrors([]);

                        requestPasswordReset({ userName: values.userName.trim() })
                            .then(() => setShowModal(true))
                            .catch(setAjaxErrors)
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting }) => (
                        <div>
                            <FormHeading
                                heading="Forgotten Password"
                                onClose={() => navigate('/login')}
                            />
                            <p>
                                Please enter the username that you use to login with. If a match
                                is found you will receive an email with further instructions.
                            </p>
                            <ErrorList className="mb-4" errors={ajaxErrors} />
                            <Form>
                                <fieldset disabled={isSubmitting}>
                                    <FormGroup name="userName">
                                        <TextBox name="userName" />
                                    </FormGroup>
                                </fieldset>
                                <ActionButton busy={isSubmitting} type="submit" isPrimary>
                                    Request Password Reset
                                </ActionButton>
                            </Form>
                        </div>
                    )}
                </Formik>
            </div>
            {showModal && (
                <NotificationModal
                    title="Email Sent"
                    message="Please check your emails for instructions on how to reset your password."
                    onRequestClose={() => navigate('/login')}
                />
            )}
        </>
    );
};

export default ForgottenPasswordPage;
