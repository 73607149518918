export default function parseModelStateErrors(data: any): Array<string> {
  const { errors } = data;
  let results: Array<string> = [];

  if (!errors) {
    return ['An unexpected error has occurred'];
  }

  Object.keys(errors).forEach(key => (results = [...results, ...errors[key]]));

  return results;
}
