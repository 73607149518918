import React from 'react';
import { IFormHeadingProps } from './types';
import styles from './FormHeading.module.css';

export const FormHeading: React.FC<IFormHeadingProps> = ({
  heading,
  subHeading,
  secondSubHeading,
  className,
  onClose,
}) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <div>
        {onClose && <span className={`e-arrow-left e-icons e-icon-left ${styles.closeButton}`} onClick={onClose}/>}
        <span className={styles.heading}>{heading}</span>
      </div>
      {subHeading && <small className={styles.subHeading}>{subHeading}</small>}
      {secondSubHeading && (
        <small className={styles.subHeading}>{secondSubHeading}</small>
      )}
    </div>
  );
};
