import React from 'react';
import { IAnswerProps } from './types';
import { MultilineTextBox } from '../../../../formikBindings';

export const MultiLineTextAnswer: React.FC<IAnswerProps> = ({
    name,
    enablestatus
}) => {

    return (
        <>
            <MultilineTextBox name={name} enabled={enablestatus} rows={10} />
        </>
    );
};
