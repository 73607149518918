import * as Yup from 'yup';

export default Yup.object({
  oldPassword: Yup.string()
    .required('Please enter your old password'),
  password: Yup.string()
    .required('Please enter a new password'),
  passwordConfirmation: Yup.string()
    .required('Please confirm password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
});
