import React from 'react';
import { FormGroup, FormSectionHeading } from '../../../forms';
import { DatePicker, TextBox, UnboundField } from '../../../formikBindings';

const ProfileTab: React.FC = () => (
    <div>
        <div className="form-row">
            <FormGroup className="col-md-6" label="Contact Id" name="contactId">
                <UnboundField name="contactId" />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-6" label="Title" name="title">
                <UnboundField name="title" />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-6" label="Forenames" name="forenames">
                <UnboundField name="forenames" />
            </FormGroup>
            <FormGroup className="col-md-6" label="Surname" name="surname">
                <UnboundField name="surname" />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-12" label="Postal Name" name="postName">
                <UnboundField name="postName" />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-6" label="Date Of Birth" name="dateOfBirth">
                <UnboundField name="dateOfBirth" enabled={false} />
            </FormGroup>
            <FormGroup className="col-md-6" label="Age" name="age">
                <UnboundField name="age" />
            </FormGroup>
        </div>

        <FormSectionHeading>IAM Membership</FormSectionHeading>

        <div className="form-row">
            <FormGroup
                className="col-md-6"
                label="Member/Sub Types"
                name="memberTier"
            >
                <UnboundField name="memberTier" />
            </FormGroup>
            <FormGroup
                className="col-md-6"
                label="Membership Grade"
                name="subscriptionGradeCode"
            >
                <UnboundField name="subscriptionGradeCode" />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup
                className="col-md-6"
                label="Sub Status"
                name="subscriptionStatus"
            >
                <UnboundField name="subscriptionStatus" />
            </FormGroup>
            <FormGroup className="col-md-6" label="???" name="subscriptionGradeCode">
                <UnboundField name="unknown" />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-12" label="Expiry Date" name="expiryDate">
                <TextBox name="expiryDate" disabled={true} />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-12" label="Membership Joined Date" name="memJoinedDate">
                <TextBox name="memJoinedDate" disabled={true} />
            </FormGroup>
        </div>

        <div className="form-row">
            <FormGroup className="col-md-6" label="Enrolled" name="enrolledDate">
                <UnboundField name="enrolledDate" />
            </FormGroup>
            <FormGroup className="col-md-6" label="???" name="subscriptionGradeCode">
                <UnboundField name="unknown2" />
            </FormGroup>
        </div>
    </div>
);

export default ProfileTab;
