import {
  IDynamicForm,
  FieldMapping,
  IDriverEvaluationReportGroup,
} from './types';
import * as Yup from 'yup';

export default (groups: Array<IDriverEvaluationReportGroup>): IDynamicForm => {
  const initialValues: any = {};
  const schemaDefinition: Yup.ObjectSchemaDefinition<any> = {};
  const fieldMappings: FieldMapping = {};

  for (let group of groups) {
    for (let question of group.questions) {
      const name = question.fileId.toString();

      initialValues[name] = question.answer;

      if (question.isRequired || question.isResultMaster) {
        schemaDefinition[name] = Yup.string()
          .required('This is a required question')
          .nullable();
      }

      fieldMappings[name] = { fileId: question.fileId, type: question.type };
    }
  }

  return {
    initialValues,
    validationSchema: Yup.object(schemaDefinition),
    fieldMappings,
  };
};
