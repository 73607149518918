import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IResetCurrentPasswordFormValues } from './types';
import { Form, Formik } from 'formik';
import ErrorList from '../errorList';
import { TextBox } from '../formikBindings';
import { FormHeading, FormGroup, ActionButton } from '../forms';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import resetCurrentPassword from './resetCurrentPassword';
import schema from './schema';
import { NotificationModal } from '../modals/notification';
import PasswordPolicyFeedback from '../passwordPolicy/PasswordPolicyFeedback';
import usePasswordPolicy from '../passwordPolicy/usePasswordPolicy';
import useUserData from '../userData';
import useAuthentication from '../authentication';

const initialValues: IResetCurrentPasswordFormValues = {
    oldPassword: '',
    password: '',
    passwordConfirmation: '',
};

const ResetCurrentPasswordPage: React.FC = () => {
    const { selectedContactFileId } = useUserData();
    const { passwordResetRequired, onLogout } = useAuthentication();

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
    const { validate, isValid } = usePasswordPolicy();

    return (
        <>
            <div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setAjaxErrors([]);

                        resetCurrentPassword({
                            fileId: selectedContactFileId,
                            oldPassword: values.oldPassword,
                            newPassword: values.password,
                        })
                            .then(() => setShowModal(true))
                            .catch(setAjaxErrors)
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting }) => (
                        <div className={passwordResetRequired ? 'p-4' : ''}>
                            <FormHeading
                                heading="Reset Password"
                            />
                            <p>
                                {passwordResetRequired && (
                                    <strong>Your password has expired. </strong>
                                )}
                                Please enter a new password that fulfills all of the rules
                                listed
                            </p>
                            <ErrorList className="mb-4" errors={ajaxErrors} />
                            <div className="row">
                                <div className="col-md-6">
                                    <Form>
                                        <fieldset disabled={isSubmitting}>
                                            <FormGroup name="oldPassword" label="Old Password">
                                                <TextBox type="password" name="oldPassword" />
                                            </FormGroup>
                                            <FormGroup name="password" label="Password">
                                                <TextBox
                                                    type="password"
                                                    name="password"
                                                    input={(event: { value: string } | undefined) => {
                                                        validate(event?.value ?? '');
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                name="passwordConfirmation"
                                                label="Confirm Password"
                                            >
                                                <TextBox type="password" name="passwordConfirmation" />
                                            </FormGroup>
                                        </fieldset>
                                        {passwordResetRequired && (
                                            <ButtonComponent type="button" cssClass="mr-2" onClick={onLogout}>
                                                Cancel
                                            </ButtonComponent>
                                        )}
                                        <ActionButton
                                            busy={isSubmitting}
                                            type="submit"
                                            isPrimary
                                            disabled={!isValid}
                                        >
                                            Reset Password
                                        </ActionButton>
                                    </Form>
                                </div>
                                <div className="col-md-6">
                                    <PasswordPolicyFeedback />
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
            {showModal && (
                <NotificationModal
                    title="Password Reset"
                    message="Your password has been successfully reset."
                    onRequestClose={() => {
                        if (passwordResetRequired) {
                            onLogout();
                        } else {
                            navigate('/', { replace: true });
                        }
                    }}
                />
            )}
        </>
    );
};

export default ResetCurrentPasswordPage;
