import React from 'react';
import { useFormikContext } from 'formik';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export const CancelButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { dirty, isSubmitting, resetForm } = useFormikContext();

    return (
        <ButtonComponent
            cssClass="mr-1"
            type="button"
            disabled={!dirty || isSubmitting}
            onClick={() => resetForm()}
        >
            {children}
        </ButtonComponent>
    );
};
