import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { FormHeading, SubmitButton } from '../../../forms';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import {
  ILocalGroupMemberDetailsFormProps,
  ILocalGroupMemberDetailsFormValues,
} from './types';
import { DropDownItem } from '../../../types';
import ErrorList from '../../../errorList';
import ProfileTab from './ProfileTab';
import AddressTab from './AddressTab';
import GroupTab from './GroupTab';
import LinksTab from './LinksTab';
import { RaiseMemberQueryModal } from '../../../modals/raiseMemberQuery';
import toSyncfusionDataSource from '../../../../utils/toSyncfusionDataSource';
import identifyChanges from './identifyChanges';
import schema from './schema';
import useUserData from '../../../userData';
import GroupLinksTab from './GroupLinksTab';
import formatName from '../../../../utils/formatName';

const LocalGroupMemberDetailsForm: React.FC<ILocalGroupMemberDetailsFormProps> = ({
    fileId,
    groupId,
  initialValues,
  promise,
  onSubmit,
    onClose,
  counties,
  isGroupOfficer
}) => {
  const [originalValues, setOriginalValues] = useState<
    ILocalGroupMemberDetailsFormValues
  >(initialValues);
  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
  const [addressTypes, setAddressTypes] = useState<
    Array<{ [key: string]: Object }>
  >([]);
  const [selectedAddressIndex, setSelectedAddressIndex] = useState<number>(0);
  const [isRaisingQuery, setIsRaisingQuery] = useState<boolean>(false);

  useEffect(() => {
    const listItems: Array<DropDownItem> = [];

    for (let index = 0; index < initialValues.addresses.length; index += 1) {
      listItems.push({
        id: index.toString(),
        displayValue: initialValues.addresses[index].addressType,
      });

      if (initialValues.addresses[index].default) {
        setSelectedAddressIndex(index);
      }
    }

      setAddressTypes(toSyncfusionDataSource(listItems));

      let surname = '';
      let forenames = '';
      let postName = '';

      initialValues.surname.split(' ').forEach((y: string) => surname += `${formatName(y)} `);
      initialValues.forenames.split(' ').forEach((y: string) => forenames += `${formatName(y)} `);
      initialValues.postName.split(' ').forEach((y: string) => postName += `${formatName(y)} `);

      initialValues.surname = surname;
      initialValues.forenames = forenames;
      initialValues.postName = postName;
  }, [initialValues]);

  const { selectedContactFileId } = useUserData(); // EU1921- TO - 18/08/2022

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={schema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const valuesToUpdate = identifyChanges(originalValues, values);

        promise(valuesToUpdate)
          .then(() => {
            resetForm({ values });
            setOriginalValues(values);
            onSubmit(typeof valuesToUpdate.contact !== 'undefined');
          })
          .catch(errors => setAjaxErrors(errors))
          .finally(() => setSubmitting(false));
      }}
    >
      {({ values }) => (
        <>
          <FormHeading
            heading="Member Details"
            subHeading={`${values.contactId} - ${values.forenames} ${values.surname}`}
            onClose={onClose}
          />
          <Form>
            <ErrorList errors={ajaxErrors} />
            <TabComponent>
              <div className="e-tab-header">
                <div>Profile</div>
                <div>Address</div>
                <div>Group</div>
                <div>Links</div>
                <div>Group Links</div>
              </div>
              <div className="e-content mt-3">
                <ProfileTab />
                <AddressTab
                                  addressTypes={addressTypes}
                                  selectedAddressIndex={selectedAddressIndex}
                                  onChangeSelectedAddressIndex={setSelectedAddressIndex}
                                  counties={counties}
                />
                <GroupTab 
                                  testClassifications={values.testClassifications}
                                  groupSpecificFieldLabels={values.group.groupSpecificFieldLabels}
                />
                <LinksTab
                                  fileId={fileId}
                                  groupId={groupId}
                  contactId={values.contactId}
                  name={`${values.forenames} ${values.surname}`}
                  initialValues={values.links}
                />
                <GroupLinksTab
                  contactFileId={fileId}
                  groupId={groupId}
                  groupLinks={values.groupLinks}
                                  isGroupOfficer={isGroupOfficer}
                              />
              </div>
            </TabComponent>

            <ButtonComponent
              cssClass="mr-1"
              type="button"
              onClick={() => setIsRaisingQuery(true)}
            >
              Raise Query
            </ButtonComponent>
            <SubmitButton>Save</SubmitButton>
          </Form>

          {isRaisingQuery && (
            <RaiseMemberQueryModal
              fileId={fileId}
              contactId={values.contactId}
              name={`${values.forenames}${values.surname}`}
              requestedBy={selectedContactFileId}
              onClose={() => setIsRaisingQuery(false)}
            />
          )}
        </>
      )}
    </Formik>
  );
};

export default LocalGroupMemberDetailsForm;
