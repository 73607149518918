import React, { useState } from 'react';
import Modal from 'react-modal';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { ChangeEventArgs } from '@syncfusion/ej2-buttons';
import { IDataProtectionAgreementDialogProps } from './types';
import styles from './DataProtectionAgreementDialog.module.css';

const DataProtectionAgreementDialog: React.FC<IDataProtectionAgreementDialogProps> = ({
  open,
  onClickContinue,
  onClickCancel,
}) => {
  const [agreed, setAgreed] = useState<boolean>(false);

  const handleAgreedChecked = (args: ChangeEventArgs | undefined) => {
    if (typeof args === 'undefined') return;
    setAgreed(args.checked || false);
  };

  return (
    <Modal
      isOpen={open}
      className="model-content"
      overlayClassName="modal-overlay"
    >
      <div className={styles.container}>
        <h1 className="text-center">Data Protection Agreement</h1>
        <div className={styles.textContainer}>
          <div>
                      <p>
                      You are about to access IAM RoadSmart's database to view, update or export specific personal information about IAM members and customers.
                      </p>
                      <p>
                          <u>Examiners & Trainers</u> <br />
                          This Personal Data can only be used strictly in accordance with the express authorisation 
                          and instructions of IAM RoadSmart, for the purpose directly related to the product or service 
                          that has been purchased or enquired about and not for any other purpose.
                          <br /><br />
                          Use of this data for any other purpose will be in breach of the Data Protection Legislation.
                      </p>
                      <p>
                          <u>Groups</u> <br />
                          Groups using the Shared Personal Data for the Group's own processing/purpose other than instructed by IAM RoadSmart, 
                          for the delivery of the product or service purchased or enquired about, shall at all times comply with their obligations as data controllers, 
                          the rights of data subjects, and all other applicable requirements under the Data Protection Legislation.
                          <br /><br />
                          Groups shall ensure that they process the Shared Personal Data fairly and lawfully and ensure that they have 
                          legitimate grounds for processing the Shared Personal Data under the Data Protection Legislation as they remain responsible 
                          for complying with all controller obligations and must be able to demonstrate, such compliance.
                      </p>

          </div>
          <p className="font-weight-bold text-center my-5">
            Tick the <span className="text-primary">I Agree</span> box to confirm that you understand and will adhere
            to this Data Protection Agreement.
          </p>
          <div className={styles.legalText}>
            <p className="font-weight-bold">Data Protection Principles</p>
            <p>
              The Act contains a code of conduct that applies to ALL personal
              data processing whether or not the organisation is exempt from the
              need to notify. The code is contained in Eight Data Protection
              Principles:
            </p>
            <ol>
              <li>
                Personal data shall be processed fairly and lawfully and, in
                particular, shall not be processed unless:
                <ol type="i">
                  <li>
                    at least one set of conditions (including "consent" of the
                    data subject) is met
                  </li>
                  <li>
                    in the case of sensitive personal data, at least one of a
                    further set of conditions (including "explicit consent" of
                    the data subject) is also met.
                  </li>
                </ol>
              </li>
              <li>
                Personal data shall be obtained only for one or more specified
                and lawful purposes, and shall not be further processed in any
                manner incompatible with that purpose or those purposes.
              </li>
              <li>
                Personal data shall be adequate, relevant and not excessive in
                relation to the purpose or purposes for which they are
                processed.
              </li>
              <li>
                Personal data processed shall be accurate and, where necessary,
                kept up to date.
              </li>
              <li>
                Personal data processed for any purpose or purposes shall not be
                kept for longer than is necessary for that purpose or those
                processes.
              </li>
              <li>
                Personal data shall be processed in accordance with the rights
                of data subjects under this Act.
              </li>
              <li>
                Appropriate technical and organisational measures shall be taken
                against unauthorised or unlawful processing of personal data and
                against accidental loss or destruction of, or damage to,
                personal data.
              </li>
              <li>
                No personal data shall be transferred to a country or territory
                outside the European Economic Areas unless that country or
                territory ensures an adequate level of protection for the rights
                and freedoms of data subjects in relation to the processing of
                personal data.
              </li>
            </ol>
            <p>
              Further information may be obtained from the Information
              Commissioner.
            </p>
          </div>

          <p className="font-weight-bold text-center mt-5">
            PLEASE NOTE THAT THE REQUIREMENTS OF THE DATA PROTECTION ACT DEMAND
            CAREFUL CONSIDERATION AND ACTION.
          </p>
          <p><small>V28.0</small></p>
        </div>
        <div>
          <CheckBoxComponent
            label="I Agree"
            checked={agreed}
            change={handleAgreedChecked}
          />
          <div className="d-flex justify-content-between mt-3">
            <ButtonComponent type="button" onClick={onClickCancel}>
              Cancel
            </ButtonComponent>
            <ButtonComponent
              type="button"
              cssClass="mr-2 e-primary"
              onClick={onClickContinue}
              disabled={!agreed}
            >
              Continue
            </ButtonComponent>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DataProtectionAgreementDialog;
