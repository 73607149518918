import React from 'react';
import { IWaitNotificationModalProps } from './types';
import Modal from 'react-modal';
import { FormHeading } from '../../forms/formHeading';

export const WaitNotificationModal: React.FC<IWaitNotificationModalProps> = ({
  title,
  message,
}) => {
  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <FormHeading heading={title} />
      <p>{message}</p>
    </Modal>
  );
};
