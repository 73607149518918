import React from 'react';
import { useField } from 'formik';
import { IFormikFieldProps } from './types';
import { CheckBoxModel } from '@syncfusion/ej2-buttons';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

export type CheckBoxProps = IFormikFieldProps &
  CheckBoxModel &
  DefaultHtmlAttributes;

export const CheckBox: React.FC<CheckBoxProps> = ({
  name,
  validate,
  ...rest
}) => {
  const [field, , helpers] = useField(name);

  return (
    <CheckBoxComponent
      immediateRender
      change={e => {
        if (typeof e === 'undefined' || !e.event) return;
        helpers.setValue(e.checked);
      }}
      onBlur={() => helpers.setTouched(true)}
      checked={field.value == '1'}
      {...rest}
    />
  );
};
