import { DropDownItem } from '../components/types';

export default function toSyncfusionDataSource(
  items: Array<DropDownItem>,
  emptyItemDescription: string | undefined = undefined,
): Array<{ [key: string]: Object }> {
  const results = items.map(item => ({ id: item.id, item: item }));

  if (typeof emptyItemDescription !== 'undefined') {
    results.unshift({
      id: '',
      item: { id: '', displayValue: emptyItemDescription },
    });
  }

  return results;
}

export const fields: object = { text: 'item.displayValue', value: 'id' };

export const transformDropDownData = (data: {
  items: Array<DropDownItem>;
}): Array<{ [key: string]: Object }> => toSyncfusionDataSource(data.items);
