import React, { useEffect, useState } from 'react';
import {
  IPasswordPolicyProviderProps,
  IPasswordPolicy,
  IPasswordPolicyRule,
} from './types';
import PasswordPolicyContext from './PasswordPolicyContext';
import createPolicyRules from './createPolicyRules';

const PasswordPolicyProvider: React.FC<IPasswordPolicyProviderProps> = ({
  minimumLength,
  minimumLetters,
  minimumNumbers,
  minimumUppercase,
  minimumLowercase,
  minimumSpecial,
  notStartOrEndWithASpace,
  notContainAnySpaces,
  children,
}) => {
  const [rules, setRules] = useState<Array<IPasswordPolicyRule>>([]);
  const [feedback, setFeedback] = useState<Array<IPasswordPolicy>>([]);
  const [isValid, setIsValid] = useState<boolean>(false);

  useEffect(() => {
    setRules(
      createPolicyRules(
        minimumLength,
        minimumLetters,
        minimumNumbers,
        minimumUppercase,
        minimumLowercase,
        minimumSpecial,
        notStartOrEndWithASpace,
        notContainAnySpaces,
      ),
    );
  }, [
    minimumLength,
    minimumLetters,
    minimumNumbers,
    minimumUppercase,
    minimumLowercase,
    minimumSpecial,
    notStartOrEndWithASpace,
    notContainAnySpaces,
  ]);

  useEffect(() => {
    setFeedback(rules.map(rule => evaluate(rule, '')));
  }, [rules]);

  const evaluate = (
    rule: IPasswordPolicyRule,
    value: string,
  ): IPasswordPolicy => ({
    text: rule.text,
    isValid: rule.validate(value),
  });

  const handleValidate = (value: string): void => {
    const results = rules.map(rule => evaluate(rule, value));

    setFeedback(results);
    setIsValid(results.every(result => result.isValid));
  };

  return (
    <PasswordPolicyContext.Provider
      value={{ feedback, isValid, validate: handleValidate }}
    >
      {children}
    </PasswordPolicyContext.Provider>
  );
};

export default PasswordPolicyProvider;
