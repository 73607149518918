import React from 'react';
import { IAjaxErrorBoundaryProps } from './types';
import BusyIndicator from '../../busyIndicator';
import ErrorList from '../../errorList';

const AjaxErrorBoundary: React.FC<IAjaxErrorBoundaryProps> = ({
  busy,
  loadingText,
  errors,
  children,
}) => {
  if (busy) {
    return <BusyIndicator text={loadingText} />;
  }

  return (
    <>{errors.length > 0 ? <ErrorList errors={errors} /> : <>{children}</>}</>
  );
};

export default AjaxErrorBoundary;
