import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { IResetPasswordFormValues } from './types';
import { Form, Formik } from 'formik';
import { FormHeading } from '../forms/formHeading';
import ErrorList from '../errorList';
import { FormGroup } from '../forms/formGroup';
import { TextBox } from '../formikBindings';
import { ActionButton } from '../forms/actionButton';
import queryString from '../../utils/queryString';
import resetPassword from './resetPassword';
import schema from './schema';
import { NotificationModal } from '../modals/notification';
import PasswordPolicyFeedback from '../passwordPolicy/PasswordPolicyFeedback';
import usePasswordPolicy from '../passwordPolicy/usePasswordPolicy';

const initialValues: IResetPasswordFormValues = {
    password: '',
    passwordConfirmation: '',
};

const ResetPasswordPage: React.FC = () => {
    const navigate = useNavigate();
    const [data, setData] = useState<any>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
    const { search } = useLocation();
    const { validate, isValid } = usePasswordPolicy();

    useEffect(() => {
        const { data } = queryString.parse(search);

        setData(data);
    }, [search]);

    return (
        <>
            <div className="container">
                <Formik
                    initialValues={initialValues}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                        setAjaxErrors([]);

                        resetPassword({ data, password: values.password })
                            .then(() => setShowModal(true))
                            .catch(setAjaxErrors)
                            .finally(() => setSubmitting(false));
                    }}
                >
                    {({ isSubmitting }) => (
                        <div>
                            <FormHeading
                                heading="Reset Password"
                                onClose={() => navigate('/login')}
                            />
                            <p>
                                Please enter a new password that fulfills all of the rules listed
                            </p>
                            <ErrorList className="mb-4" errors={ajaxErrors} />
                            <div className="row">
                                <div className="col-md-6">
                                    <Form>
                                        <fieldset disabled={isSubmitting}>
                                            <FormGroup name="password" label="Password">
                                                <TextBox
                                                    type="password"
                                                    name="password"
                                                    input={(event: { value: string } | undefined) => {
                                                        validate(event?.value ?? '');
                                                    }}
                                                />
                                            </FormGroup>
                                            <FormGroup
                                                name="passwordConfirmation"
                                                label="Confirm Password"
                                            >
                                                <TextBox type="password" name="passwordConfirmation" />
                                            </FormGroup>
                                        </fieldset>
                                        <ActionButton busy={isSubmitting} type="submit" isPrimary disabled={!isValid}>
                                            Reset Password
                                        </ActionButton>
                                    </Form>
                                </div>
                                <div className="col-md-6">
                                    <PasswordPolicyFeedback />
                                </div>
                            </div>
                        </div>
                    )}
                </Formik>
            </div>
            {showModal && (
                <NotificationModal
                    title="Password Reset"
                    message="Your password has been successfully reset."
                    onRequestClose={() => navigate('/login', { replace: true })}
                />
            )}
        </>
    );
};

export default ResetPasswordPage;
