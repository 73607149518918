import React, { useEffect, useRef, useState } from 'react';
import {
  ColumnDirective,
  ColumnsDirective,
  GridComponent,
  RowSelectEventArgs
} from '@syncfusion/ej2-react-grids';
import { IContactAtLocalGroupLink, IGroupLinksTabProps } from './types';
import { AddId, DeleteId, GroupLinksToolbar } from './groupLinkGridToolbar';
import ErrorList from '../../../errorList';
import DeleteGroupLinkModel from './modals/deleteGroupLink/DeleteGroupLinkModel';
import { NotificationModal } from '../../../modals';
import AddGroupLinkModel from './modals/addGroupLink/AddGroupLinkModel';
import getLocalGroupMemberDetails from './getLocalGroupMemberDetails';

const GroupLinksTab: React.FC<IGroupLinksTabProps> = ({ groupLinks, contactFileId, groupId, isGroupOfficer }) => {
  const [selectedToolbarOption, setSelectedToolbarOption] = useState<string>();
  const [selectedContactAtLocalGroupLink, setSelectedContactAtLocalGroupLink] = useState<IContactAtLocalGroupLink>({ fileId: 0, description: '' });

  const [showAddModel, setShowAddModel] = useState<boolean>(false);
  const [showDeleteModel, setShowDeleteModel] = useState<boolean>(false);

  const [gridError, setGridErrors] = useState<Array<string>>([]);
  const [ContactLocalgroupLinks, setContactLocalGroupLinks] = useState<Array<IContactAtLocalGroupLink>>();
  const [refreshLinks, setRefreshLinks] = useState<boolean>(false);

    useEffect(() => {
        if (groupLinks) {
            setContactLocalGroupLinks(groupLinks);
        }

        if (refreshLinks) {
            getLocalGroupMemberDetails(groupId, contactFileId)
                .then(res => setContactLocalGroupLinks(res.data.groupLinks))
                .catch(errors => setGridErrors(errors));
        }
    }, [groupLinks, refreshLinks]);

  const handleRowClick = (args: RowSelectEventArgs | undefined) => {
    if (typeof args === 'undefined' || !args.data) return;

    if (args.isInteracted) {
      setSelectedContactAtLocalGroupLink(args.data as IContactAtLocalGroupLink);
    }
  };

  const gridElement = useRef<GridComponent>(null);

  const handleToolbarClick = (args: any) => {
    if (!gridElement) return;

    switch (args.item.id) {
      case AddId:
      case DeleteId:
        setSelectedToolbarOption(args.item.id);
        break;
        
      default:
        break;
    }
  };

  useEffect(() => {
    if (typeof(selectedToolbarOption) !== 'undefined')
    {
      setRefreshLinks(false);
      setGridErrors([]);
      
      if (selectedToolbarOption === AddId) {
        setShowAddModel(true);
      } else if (selectedToolbarOption === DeleteId && selectedContactAtLocalGroupLink.fileId !== 0) {
        setShowDeleteModel(true);
      } else if (selectedContactAtLocalGroupLink.fileId === 0 && selectedToolbarOption === DeleteId) {
        setGridErrors(["You must select a contact at local group link to use the delete option."]);
        setSelectedToolbarOption(undefined);
      }
    }

  }, [selectedToolbarOption]);

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [showSuccessModel, setShowSuccessModel] = useState<boolean>(false);

  const handleComplete = (toolbarOption: string) => {
    if (toolbarOption === AddId) {
      setShowAddModel(false);
      setSuccessMessage("Contact at Local group link created.");
    } else if (toolbarOption === DeleteId) {
      setShowDeleteModel(false);
      setSuccessMessage("Contact at Local group link deleted.");
    }

    setShowSuccessModel(true);
  }
  
  return (
    <div>
      {!isGroupOfficer ? (
        <ErrorList errors={["You are unable to group links tab."]} className="mx-2" />
      ) : (
        <>
          <ErrorList errors={gridError} className="mb-4" />
          <GridComponent
            ref={gridElement}
            className="my-3"
            dataSource={ContactLocalgroupLinks}
            toolbar={GroupLinksToolbar}
            toolbarClick={handleToolbarClick}
            rowSelected={handleRowClick}
          >
            <ColumnsDirective>
              <ColumnDirective field="description" headerText="Description" />
            </ColumnsDirective>
          </GridComponent>
          {showAddModel && (
            <AddGroupLinkModel
              contactFileId={contactFileId}
              groupId={groupId}
              setShowModel={setShowAddModel}
              handleComplete={handleComplete} />
          )}
          {showDeleteModel && (
            <DeleteGroupLinkModel
              localGroupLinkFileId={selectedContactAtLocalGroupLink.fileId}
              setShowModel={setShowDeleteModel}
              handleComplete={handleComplete} />
          )}
          {showSuccessModel && (
            <NotificationModal
              title="Success"
              message={successMessage}
              onRequestClose={() => {
                  setShowSuccessModel(false);
                  setRefreshLinks(true);
              }}
            />
          )}
        </>
      )}
    </div>
  )
};

export default GroupLinksTab;
