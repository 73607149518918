import React from 'react';
import { useField } from 'formik';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { DropDownListModel } from '@syncfusion/ej2-dropdowns';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { DropDownListTypecast } from '@syncfusion/ej2-react-dropdowns';
import { IFormikFieldProps } from './types';
import { fields } from '../../utils/toSyncfusionDataSource';

export type DropDownListProps = IFormikFieldProps &
    ((DropDownListModel & DefaultHtmlAttributes) | DropDownListTypecast);

export const DropDownList: React.FC<DropDownListProps> = ({
  name,
  ...rest
}) => {
  const [field, , helpers] = useField(name);


  return (
    <DropDownListComponent
      name={name}
      immediateRender
      change={e => {
        if (typeof e === 'undefined' || !e.e) return;
        helpers.setValue(e.value);
      }}
      blur={() => helpers.setTouched(true)}
      value={field.value}
      fields={fields}
      {...rest}
    />
  );
};
