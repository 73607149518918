import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ILoginResult, IUser } from '../login/types';
import localStorageService from '../../services/localStorageService';
import BusyIndicator from '../busyIndicator';
import AuthenticationContext from './AuthenticationContext';
import jwtDecode from 'jwt-decode';
import validateToken from './validateToken';

const AuthenticationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [busy, setBusy] = useState<boolean>(true);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
    const [passwordResetRequired, setPasswordResetRequired] = useState<boolean>(
        false,
    );
    const [user, setUser] = useState<IUser | undefined>(undefined);

    const logout = useCallback(() => {
        localStorageService.clear();
        setUser(undefined);
        navigate('/login');
    }, [navigate]);

    // when the component mounts check to see if a token already exists
    // this could happen on either a page refresh or if the user didn't log out last time
    useEffect(() => {
        const authToken = localStorageService.getAuthToken();
        const accessToken = localStorageService.getAccessToken();

        if (authToken !== null && accessToken !== null) {
            validateToken(authToken, accessToken)
                .then(response => {
                    const decodedJwtToken: any = jwtDecode(authToken);

                    const user: IUser = {
                        userName:
                            decodedJwtToken[
                            'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
                            ],
                        contacts: response.data.contacts,
                        roles:
                            decodedJwtToken[
                            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                            ],
                    };

                    setPasswordResetRequired(decodedJwtToken['pwr'] === 'true');
                    setUser(user);
                })
                .catch(error => {
                    console.error(error);
                    logout();
                })
                .finally(() => setBusy(false));
        } else {
            setBusy(false);
        }
    }, [logout]);

    useEffect(() => {
        if (typeof user === 'undefined') {
            setIsAuthenticated(false);
        } else {
            setIsAuthenticated(true);
            if (pathname === '/login') {
                navigate('/', { replace: true });
            }
        }
    }, [user, navigate, pathname]);

    const handleLoggedIn = (loginResult: ILoginResult) => {
        const {
            authToken,
            accessToken,
            ...user
        } = loginResult;

        setPasswordResetRequired(passwordResetRequired);

        localStorageService.setTokens(authToken, accessToken);
        localStorageService.clearDevice();

        const decodedJwtToken: any = jwtDecode(authToken);
        user.roles =
            decodedJwtToken[
            'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ];

        setPasswordResetRequired(decodedJwtToken['pwr'] === 'true');
        setUser(user);
    };

    if (busy) return <BusyIndicator text="Authenticating" fullScreen />;

    return (
        <AuthenticationContext.Provider
            value={{
                isAuthenticated,
                passwordResetRequired,
                user,
                onLogout: logout,
                onLoggedIn: handleLoggedIn,
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    );
};

export default AuthenticationProvider;
