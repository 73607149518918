import {
  ILocalGroupMemberDetailsFormValues,
  IAddress,
  IGroup,
  IUpdatableMember,
  IUpdatableAddress,
  IUpdateCommand,
} from './types';
import isDifferent from '../../../../utils/isDifferent';

const identifyChanges = (
  initialValues: ILocalGroupMemberDetailsFormValues,
  values: ILocalGroupMemberDetailsFormValues,
): IUpdateCommand => {
  const valuesToUpdate: IUpdateCommand = {
      fileId: values.fileId,
      groupId: values.groupId
  };

  // contact
  const originalContact = extractUpdatableContactFieldsFrom(initialValues);
  const currentContact = extractUpdatableContactFieldsFrom(values);

  if (isDifferent(originalContact, currentContact)) {
    valuesToUpdate.contact = currentContact;
  }

  const addressesToUpdate: Array<IUpdatableAddress> = [];

  for (let index = 0; index < values.addresses.length; index += 1) {
    const originalAddress = extractUpdatableAddressFieldsFrom(
      initialValues.addresses[index],
    );
    const currentAddress = extractUpdatableAddressFieldsFrom(
      values.addresses[index],
    );

    if (isDifferent(originalAddress, currentAddress)) {
      addressesToUpdate.push(currentAddress);
    }
  }

  if (addressesToUpdate.length > 0) {
    valuesToUpdate.addresses = addressesToUpdate;
  }

  // group
  const originalGroup = extractUpdatableGroupFieldsFrom(initialValues);
  const currentGroup = extractUpdatableGroupFieldsFrom(values);

  if (isDifferent(originalGroup, currentGroup)) {
    valuesToUpdate.group = currentGroup;
  }

  return valuesToUpdate;
};

const extractUpdatableContactFieldsFrom = (
  values: ILocalGroupMemberDetailsFormValues,
): IUpdatableMember => ({
  dateOfBirth: values.dateOfBirth,
  mobile: values.mobile,
  email: values.email,
});

const extractUpdatableAddressFieldsFrom = (
  address: IAddress,
): IUpdatableAddress => ({
  fileId: address.fileId,
  premise: address.premise,
  street: address.street,
  district: address.district,
  town: address.town,
  county: address.county,
  postcode: address.postcode,
  telephone1: address.telephone1,
  telephone2: address.telephone2,
        email: address.email,
  mobile: address.mobile,
});

const extractUpdatableGroupFieldsFrom = (
  values: ILocalGroupMemberDetailsFormValues,
): IGroup => ({
  ...values.group,
});

export default identifyChanges;
