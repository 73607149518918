import React, { useMemo } from 'react';
import { ITextAnswerProps } from './types';
import { TextBox, DropDownList } from '../../../../formikBindings';
import toSyncfusionDataSource from '../../../../../utils/toSyncfusionDataSource';

export const TextAnswer: React.FC<ITextAnswerProps> = ({
    name,
    restrictions,
    enablestatus
}) => {
    const memoizedDataSource = useMemo(() => {
        return restrictions ? toSyncfusionDataSource(restrictions) : [];
    }, [restrictions]);

    return (
        <>
            {restrictions?.length ? (
                <DropDownList name={name} dataSource={memoizedDataSource} enabled={enablestatus} />
            ) : (
                    <TextBox name={name} enabled={enablestatus} />
                )}
        </>
    );
};
