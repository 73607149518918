import { useEffect, useState } from 'react';
import axios from 'axios';
import { instance } from '../../services/api';
import makeCancelable from '../../utils/makeCancelable';

const initialState = {
  busy: true,
  data: undefined,
  errors: [],
};

export const useDataFetcher = (
  url: string,
  config?: { transformer: (data: any) => any },
): { busy: boolean; data: any; errors: Array<string> } => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (url) {
      const source = axios.CancelToken.source();

      setState(initialState);

      const cancelableRequest = makeCancelable(
        instance.get(url, {
          cancelToken: source.token,
        }),
        source,
      );

      const transformData = (data: any): any => {
        if (!config || !config.transformer) return data;

        return config.transformer(data);
      };

      cancelableRequest.promise
        .then(response =>
          setState({
            busy: false,
            data: transformData(response.data),
            errors: [],
          }),
        )
        .catch(errors => {
          if (!errors.isCanceled) {
            setState({ busy: false, data: undefined, errors });
          }
        });

      return () => {
        cancelableRequest.cancel();
      };
    }
  }, [url, config]);

  return {
    busy: state.busy,
    data: state.data,
    errors: state.errors,
  };
};
