import React, { useState, useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import AjaxErrorBoundary from '../../ajax/errorBoundary';
import { IModeratorPageRouteParams } from './types';
import { useDataFetcher } from '../../hooks';
import {
  Answer,
  IDriverEvaluationReport,
} from '../driverEvaluationReport/types';
import DriverEvaluationReport from '../driverEvaluationReport/DriverEvaluationReport';
import ErrorList from '../../errorList';
import updateResults from './updateResults';

const ModeratorPage: React.FC = () => {
  const { id } = useParams<IModeratorPageRouteParams>();

  const [token, setToken] = useState<string>('');
  const [report, setReport] = useState<IDriverEvaluationReport>({
    bookingFileId: 0,
    isAssessor: false,
    attendanceConfirmed: false,
    resultsSubmitted: false,
    groups: [],
  });

  const [isComplete, setIsComplete] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);

  const { busy, errors, data } = useDataFetcher(
    `/api/driverEvaluations/${id}/moderator`,
  );

  useEffect(() => {
    if (data) {
      setToken(data.token);
      setReport(data.report);
    }
  }, [data]);

  useLayoutEffect(() => {
    if (ajaxErrors.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [ajaxErrors]);

  useLayoutEffect(() => {
    if (ajaxErrors.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [ajaxErrors]);

  const handleReportUpdated = (results: Array<Answer>, complete: boolean) => {
    setIsSaving(true);

    updateResults({ id, token, results, complete })
      .then(() => setIsComplete(complete))
      .catch(setAjaxErrors)
      .finally(() => setIsSaving(false));
  };

  return (
    <AjaxErrorBoundary busy={busy} errors={errors} loadingText="Loading">
      {report.groups.length > 0 && (
        <div className="container">
          <div className="my-4">
            <h2>Driver Evaluation Report</h2>
            <ErrorList errors={ajaxErrors} />
            <DriverEvaluationReport
                          isAssessor
                          attendanceConfirmed={report.attendanceConfirmed}
                          resultsSubmitted={report.resultsSubmitted || isComplete}
                          groups={report.groups}
                          busy={isSaving}
                          pdfUrl={''}
                          requiresAttention={false}
                          onReportUpdated={handleReportUpdated}
            />
          </div>
        </div>
      )}
    </AjaxErrorBoundary>
  );
};

export default ModeratorPage;
