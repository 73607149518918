import React from 'react';
import { DropDownList } from '../../../../formikBindings';
import { IAnswerProps } from './types';

export const ScoreAnswer: React.FC<IAnswerProps> = ({ name, enablestatus }) => (
    <DropDownList
        name={name}
        enabled={enablestatus}
        dataSource={[
            '1',
            '2',
            '3',
            '4',
            '5',
            'Low Risk',
            'Medium Risk',
            'High Risk',
        ]}
    />
);
