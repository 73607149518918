import './global';
import React from 'react';
import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import App from './components/app/App';
import { L10n, loadCldr, setCulture } from '@syncfusion/ej2-base';
import * as cagregorian from '../node_modules/cldr-data/main/en-GB/ca-gregorian.json';
import * as timeZoneNames from '../node_modules/cldr-data/main/en-GB/timeZoneNames.json';
import * as numbers from '../node_modules/cldr-data/main/en-GB/numbers.json';
import * as numberingSystems from '../node_modules/cldr-data/supplemental/numberingSystems.json';
import * as currencies from '../node_modules/cldr-data/main/en-GB/currencies.json';
import * as serviceWorker from './serviceWorker';
import { registerLicense } from '@syncfusion/ej2-base';

// https://ej2.syncfusion.com/react/documentation/licensing/license-key-registration
registerLicense('ORg4AjUWIQA/Gnt2V1hiQlhPd11dXmJWd1p/THNYflR1fV9DaUwxOX1dQl9nSX1Tc0VkWXZad3VdRGE=');

// https://ej2.syncfusion.com/react/documentation/common/internationalization/
loadCldr(cagregorian, timeZoneNames, numbers, numberingSystems, currencies);

L10n.load({
  en: {
    schedule: {
      saveButton: 'Add',
      cancelButton: 'Cancel',
      deleteButton: 'Remove',
      newEvent: 'Create Calendar Appointment',
      editEvent: 'Edit Calendar Appointment',
    },
  },
});

setCulture('en');

Modal.setAppElement('#root');

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
