import React, { useState } from 'react';
import { IRaiseMemberQueryModalProps } from './types';
import Modal from 'react-modal';
import { Formik, Form } from 'formik';
import ErrorList from '../../errorList';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { SubmitButton, FormGroup, FormHeading } from '../../forms';
import { TextBox, UnboundField } from '../../formikBindings';
import schema from './schema';
import raiseMemberQuery from './raiseMemberQuery';

export const RaiseMemberQueryModal: React.FC<IRaiseMemberQueryModalProps> = ({
  fileId,
  contactId,
  name,
  requestedBy,
  onClose,
}) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);

  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <FormHeading heading="Contact Note" />
      <Formik
        initialValues={{ title: '', details: '' }}
        validationSchema={schema}
        onSubmit={values => {
          setAjaxErrors([]);
          setIsSaving(true);

          raiseMemberQuery({ fileId, requestedBy, ...values })
            .then(() => onClose())
            .catch(setAjaxErrors)
            .finally(() => setIsSaving(false));
        }}
      >
        <Form>
          <ErrorList errors={ajaxErrors} />
          <fieldset disabled={isSaving}>
            <FormGroup name="member" label="Member">
              <UnboundField value={`${name}[${contactId}]`} />
            </FormGroup>
            <FormGroup name="title" label="Title">
              <TextBox name="title" />
            </FormGroup>
            <FormGroup name="details" label="Details">
              <TextBox name="details" multiline />
            </FormGroup>
          </fieldset>
          <div className="d-flex justify-content-between mt-3">
            <ButtonComponent
              type="button"
              cssClass="e-secondary"
              onClick={onClose}
            >
              Cancel
            </ButtonComponent>
            <SubmitButton>Save</SubmitButton>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
};
