import React, { useState, useEffect } from 'react';
import { IDriverEvaluationReportProps, IDynamicForm, Answer } from './types';
import { Formik, Form } from 'formik';
import { FormSectionHeading, ActionButton } from '../../forms';
import Question from './question';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import createDynamicForm from './createDynamicForm';
import { QuestionTypes } from '../../../constants';
import toUtcDate from '../../../utils/toUtcDate';

const DriverEvaluationReport: React.FC<IDriverEvaluationReportProps> = ({
    busy,
    isAssessor,
    attendanceConfirmed,
    resultsSubmitted,
    groups,
    pdfUrl,
    requiresAttention,
    onReportUpdated
}) => {
    const [dynamicForm, setDynamicForm] = useState<IDynamicForm>();

    useEffect(() => {
        if (groups) {
            setDynamicForm(createDynamicForm(groups));
        }
    }, [groups]);

    const handleUpdateResults = (values: any, complete: boolean): void => {
        if (!dynamicForm?.fieldMappings) return;

        const results: Array<Answer> = [];

        Object.keys(values).forEach(key => {
            const questionFileId: number = Number.parseInt(key);
            const fieldMapping = dynamicForm.fieldMappings[questionFileId];

            const result: Answer = { fileId: questionFileId, answer: null, fieldtype: fieldMapping.type};

            if (values[questionFileId] !== null) {
                switch (fieldMapping.type) {
                    case QuestionTypes.DateTime:
                        result.answer =
                            values[key] instanceof Date
                                ? toUtcDate(values[key]).toISOString()
                                : values[key];
                        break;

                    case QuestionTypes.Check:
                        result.answer = values[key] ? '1' : '0';
                        break;

                    default:
                        result.answer = values[key].toString();
                }
            }

            results.push(result);
        });

        onReportUpdated(results, complete);
    };

    return (
        <>
            {dynamicForm?.initialValues && (
                <Formik
                    initialValues={dynamicForm.initialValues}
                    validationSchema={dynamicForm.validationSchema}
                    onSubmit={values => handleUpdateResults(values, true)}
                >
                    {({ values }) => (
                        <Form>
                            <fieldset
                                disabled={
                                    busy ||
                                    requiresAttention ||
                                    !isAssessor ||
                                    resultsSubmitted ||
                                    !attendanceConfirmed
                                }
                            >
                                {groups.map(group => (
                                    <div key={group.heading}>
                                        <FormSectionHeading>{group.heading}</FormSectionHeading>
                                        {group.questions.map(question => (
                                            <Question key={question.fileId} {...question}
                                                enablestatus={
                                                    !busy &&
                                                    !requiresAttention &&
                                                    isAssessor &&
                                                    !resultsSubmitted ||
                                                    attendanceConfirmed
                                                } />
                                        ))}
                                    </div>
                                ))}
                            </fieldset>
                            {attendanceConfirmed && (
                                <div className="text-right">
                                    {pdfUrl && (
                                        <ButtonComponent
                                            type="button"
                                            cssClass="e-link mr-1"
                                            disabled={busy}
                                            onClick={() => window.open(pdfUrl, '_blank')}
                                        >
                                            Show PDF
                                        </ButtonComponent>
                                    )}
                                    {isAssessor && !resultsSubmitted && !requiresAttention && (
                                        <>
                                            <ActionButton
                                                busy={busy}
                                                type="button"
                                                cssClass="e-success"
                                                onClick={() => handleUpdateResults(values, false)}
                                            >
                                                Update Results
                      </ActionButton>
                                            <ButtonComponent
                                                type="submit"
                                                cssClass="e-danger ml-1"
                                                disabled={busy || resultsSubmitted}
                                            >
                                                Submit Results
                      </ButtonComponent>
                                        </>
                                    )}
                                </div>
                            )}
                            {!attendanceConfirmed && <p>Attendance has not been confirmed</p>}
                            {resultsSubmitted && <p>This report has been submitted</p>}
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};

export default DriverEvaluationReport;
