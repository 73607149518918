import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ErrorBoundary from '../errorBoundary';
import AuthenticationProvider from '../authentication/AuthenticationProvider';
import UserDataProvider from '../userData/UserDataProvider';
import BusyIndicator from '../busyIndicator';
import LoginPage from '../login';
import RequireAuth from '../routes/requireAuth';
import ModeratorPage from '../assessments/moderator';
import ForgottenPasswordPage from '../forgottenPassword';
import ResetPasswordPage from '../resetPassword';
import ResetCurrentPasswordPage from '../resetCurrentPassword';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import MemberDetailFull from '../dashboard/containers';

const baseUrl =
    document.getElementsByTagName('base')[0].getAttribute('href') || '/';

const Shell = lazy(() => import(/* webpackChunkName: "Shell" */ '../shell'));

const App: React.FC = () => {
    return (
        <Router basename={baseUrl}>
            <ErrorBoundary>
                <AuthenticationProvider>
                    <UserDataProvider>
                        <Suspense
                            fallback={<BusyIndicator text="Loading assets" fullScreen />}
                        >
                            <Routes>
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/forgottenPassword" element={<ForgottenPasswordPage />} />
                                <Route path="/resetPassword" element={<ResetPasswordPage />} />
                                <Route path="/resetPassword/current" element={<ResetCurrentPasswordPage />} />
                                <Route path="/moderator/assessments/:id" element={<ModeratorPage />} />
                                <Route
                                    path="/memberdetailfull/:localGroupFileId/:id"
                                    element={
                                        <RequireAuth>
                                            <MemberDetailFull />
                                        </RequireAuth>
                                    }
                                />
                                <Route
                                    path="/*"
                                    element={
                                        <RequireAuth>
                                            <Shell />
                                        </RequireAuth>
                                    }
                                />
                            </Routes>
                        </Suspense>
                    </UserDataProvider>
                </AuthenticationProvider>
            </ErrorBoundary>
        </Router>
    );
};

export default App;
