import React from 'react';
import Modal from 'react-modal';
import { IDeleteLinkConfirmationModalProps } from './types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export const DeleteLinkConfirmationModal: React.FC<IDeleteLinkConfirmationModalProps> = ({
  hasPermission,
  onConfirm,
    onCancel,
    contactLinksCount
}) => (
  <Modal isOpen className="model-content" overlayClassName="modal-overlay">
    <div>
      <h1 className="text-center">Member Group Link</h1>
      {hasPermission ? (
        <div>
          <p className="mt-3">
            Do you wish to remove this link (Group Role) between member and the Local Group?
          </p>
          {contactLinksCount === 1 &&
            <p className="mt-3 text-danger">
                WARNING: Deleting the last link will mark this contacts group address as inactive.
            </p>
          }
        </div>
      ) : (
        <div>
          <p className="mt-3">
            You are not authorised to remove a membership link belonging to
            another Group.
          </p>
        </div>
      )}
      <div className="d-flex justify-content-between mt-3">
        <ButtonComponent type="button" onClick={onCancel}>
          {hasPermission ? 'No' : 'Close'}
        </ButtonComponent>
        {hasPermission && (
          <ButtonComponent
            type="button"
            cssClass="mr-2 e-danger"
            onClick={onConfirm}
          >
            Yes
          </ButtonComponent>
        )}
      </div>
    </div>
  </Modal>
);
