import React from 'react';
import { FormGroup, FormSectionHeading } from '../../../forms';
import { TextBox, DatePicker } from '../../../formikBindings';
import { IGroupTabProps } from './types';
import {
  ColumnDirective,
  ColumnsDirective,
  Filter,
  GridComponent,
  Inject,
  Sort,
} from '@syncfusion/ej2-react-grids';

const GroupTab: React.FC<IGroupTabProps> = ({ testClassifications, groupSpecificFieldLabels }: IGroupTabProps) => {
  const dataSource = testClassifications.map(classification => ({
    description: classification,
  }));

  return (
    <div>
      <div className="form-row">
        <FormGroup
          className="col-md-6"
          label={groupSpecificFieldLabels?.find(label => label.fieldId === 432)?.label || "Member Ref 1"}
          name="group.memRef1"
        >
          <TextBox name="group.memRef1" />
        </FormGroup>
        <FormGroup
          className="col-md-6"
          label={groupSpecificFieldLabels?.find(label => label.fieldId === 433)?.label || "Member Ref 2"}
          name="group.memRef2"
        >
          <TextBox name="group.memRef2" />
        </FormGroup>
      </div>

      <div className="form-row">
        <FormGroup
          className="col-md-6"
          label={groupSpecificFieldLabels?.find(label => label.fieldId === 434)?.label || "Enrol Date"}
          name="group.enrolDate"
        >
          <DatePicker name="group.enrolDate" />
        </FormGroup>
        <FormGroup
          className="col-md-6"
          label={groupSpecificFieldLabels?.find(label => label.fieldId === 435)?.label || "Expiry Date"}
          name="group.expiryDate"
        >
          <DatePicker name="group.expiryDate" />
        </FormGroup>
      </div>

          <FormGroup
              label={groupSpecificFieldLabels?.find(label => label.fieldId === 436)?.label || "Member Value"}
              name="group.memValue">
        <TextBox type="number" name="group.memValue" />
      </FormGroup>
          <FormGroup
              label={groupSpecificFieldLabels?.find(label => label.fieldId === 437)?.label || "How Heard"}
              name="group.howHeard">
        <TextBox name="group.howHeard" />
      </FormGroup>
          <FormGroup
              label={groupSpecificFieldLabels?.find(label => label.fieldId === 438)?.label || "How Joined"}
              name="group.howJoined">
        <TextBox name="group.howJoined" />
      </FormGroup>

      <div className="form-row">
              <FormGroup
                  className="col-md-6"
                  label={groupSpecificFieldLabels?.find(label => label.fieldId === 439)?.label || "Tag 1"}
                  name="group.tag1">
          <TextBox name="group.tag1" />
        </FormGroup>
              <FormGroup
                  className="col-md-6"
                  label={groupSpecificFieldLabels?.find(label => label.fieldId === 440)?.label || "Tag 2"}
                  name="group.tag2">
          <TextBox name="group.tag2" />
        </FormGroup>
      </div>

          <FormGroup
              label={groupSpecificFieldLabels?.find(label => label.fieldId === 441)?.label || "Ref Date"}
              name="group.refDate">
        <DatePicker name="group.refDate" />
      </FormGroup>

      <FormSectionHeading>IAM Test Classifications</FormSectionHeading>

      <GridComponent
        dataSource={dataSource}
        allowSorting
        allowFiltering
        filterSettings={{
          mode: 'Immediate',
          immediateModeDelay: 100,
        }}
      >
        <ColumnsDirective>
          <ColumnDirective field="description" headerText="Classification" />
        </ColumnsDirective>
        <Inject services={[Sort, Filter]} />
      </GridComponent>
    </div>
  );
};

export default GroupTab;
