import axios, { AxiosPromise } from 'axios';

const validateToken = (authToken: string, accessToken: string): AxiosPromise =>
  axios.post('api/accounts/validate', null, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      'x-auth': accessToken,
    },
  });

export default validateToken;
