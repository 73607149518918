import React, { useState } from 'react';
import Modal from 'react-modal';
import { IDeleteLinkModalProps } from './types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { Form, Formik } from 'formik';
import ErrorList from '../../../../../errorList';
import { FormGroup, ActionButton } from '../../../../../forms';
import { UnboundField, TextBox } from '../../../../../formikBindings';
import schema from './schema';
import deleteGroupMemberLink from './deleteGroupMemberLink';

export const DeleteLinkModal: React.FC<IDeleteLinkModalProps> = ({
  addressFileId,
  groupRoleDescId,
  contactId,
  name,
  onCancel,
  onDelete,
}) => {
  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <div>
        <h1 className="text-center">Group Member Deletion</h1>
        <Formik
          initialValues={{ reason: '' }}
          validationSchema={schema}
          onSubmit={values => {
            setAjaxErrors([]);
            setIsDeleting(true);

            deleteGroupMemberLink({
              addressFileId,
              groupRoleDescId,
              reason: values.reason,
            })
              .then(onDelete)
              .catch(setAjaxErrors)
              .finally(() => setIsDeleting(false));
          }}
        >
          <Form>
            <ErrorList errors={ajaxErrors} />
            <fieldset disabled={isDeleting}>
              <FormGroup label="Member" name="member">
                <UnboundField value={`[${contactId}] ${name}`} />
              </FormGroup>
              <FormGroup label="Title" name="title">
                <UnboundField value="Group Member Deletion" />
              </FormGroup>
              <FormGroup name="reason" label="Reason">
                <TextBox name="reason" multiline />
              </FormGroup>
            </fieldset>
            <div className="d-flex justify-content-between mt-3">
              <ButtonComponent type="button" onClick={onCancel}>
                Cancel
              </ButtonComponent>
              <ActionButton
                busy={isDeleting}
                type="submit"
                cssClass="mr-2 e-danger"
              >
                Delete
              </ActionButton>
            </div>
          </Form>
        </Formik>
      </div>
    </Modal>
  );
};
