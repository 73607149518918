import React from 'react';
import { ButtonModel } from '@syncfusion/ej2-buttons';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { IActionButtonProps } from './types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';

export const ActionButton: React.FC<IActionButtonProps &
  ButtonModel &
  DefaultHtmlAttributes> = ({ busy, children, ...rest }) => {
  return (
    <ButtonComponent disabled={busy} {...rest}>
      {busy && <i className="fas fa-spinner fa-spin mr-2" />}
      {children}
    </ButtonComponent>
  );
};
