import { useContext } from 'react';
import { IPasswordPolicyContext } from './types';
import PasswordPolicyContext from './PasswordPolicyContext';

const usePasswordPolicy = (): IPasswordPolicyContext => {
  const context = useContext(PasswordPolicyContext);

  if (!context) {
    throw new Error(
      'This component must be a <PasswordPolicyProvider> component',
    );
  }

  return context;
};

export default usePasswordPolicy;
