export const UserRoles = Object.freeze({
  All: '0',
  LocalGroupUser: '1',
  ShowDiary: '2',
  EditAssessments: '3',
  ShowExpenses: '5',
  ListCourses: '6',
  WebAdmin: '7',
  ManageEquipment: '9', // EU1833 - TO - 27/09/2022
  LocalGroupLinkMaintenance: '10' // EU2676 - TO - 17/10/2022
});

export const QuestionTypes = Object.freeze({
  Check: 7991,
  DateTime: 7993,
  IdentifiedTargetedCompletedSource: 7994,
  Number: 7997,
  Score: 7996,
  Text: 7992,
    TextReadonly: 7995,
    TextMomo: 66397 
});

// EU1833 - TO - 28/09/2022
export const EquipmentHQFileId = 30012593;

// EU2726 - TO - 15/11/2022
export const PortalIssuesFormLink = 'https://www.iamroadsmart.com/darts-portal-updates/darts-issue-form'
