import React from 'react';
import { IErrorListProps } from './types';
import styles from './ErrorList.module.css';

const ErrorList: React.FC<IErrorListProps> = ({ className, errors }) => {
  if (errors.length === 0) return null;

  return (
    <ul className={`${styles.list} ${className}`}>
      {errors.map(error => (
        <li key={error}>{error}</li>
      ))}
    </ul>
  );
};

export default ErrorList;
