import React from 'react';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { IDisplayFormGroupProps } from './types';

export const DisplayFormGroup: React.FC<IDisplayFormGroupProps> = ({
  colSize,
  label,
  name,
  value,
}) => (
  <div className={`form-group col-md-${colSize}`}>
    <label htmlFor="contactId">{label}</label>
    <TextBoxComponent id={name} name={name} value={value} enabled={false} />
  </div>
);
