import React from 'react';
import { useField } from 'formik';
import { IFormikFieldProps } from './types';
import { DatePickerModel } from '@syncfusion/ej2-calendars';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';

export type DatePickerProps = IFormikFieldProps &
  DatePickerModel &
  DefaultHtmlAttributes;

//export const DatePicker: React.FC<DatePickerProps> = props => {
export const DatePicker: React.FC<any> = props => {
  const [field, , helpers] = useField(props);

  return (
    <DatePickerComponent
      immediateRender
          format="dd/MM/yyyy"
      {...field}
          {...props}
          change={e => {
              // no event args
              if (!e) return;

              if (!e.value) {
                  // new value is null
                  helpers.setValue(null);
                  return;
              }

              let currentValue = field.value;
              var currentDate;
              var newDate;
              var newValue;

              if (!currentValue) {
                  // current value is null
                  helpers.setValue(e.value);
                  return;
              }

              if (currentValue instanceof Date) {
                  // current value is a date, get the ISO string representation
                  currentDate = new Date(currentValue);
                  currentValue = currentDate.toISOString().substring(0, 10);
              }

              if (e.value instanceof Date) {
                  // current value is a date, get the ISO string representation
                  newDate = new Date(e.value);
                  newDate = new Date(newDate?.getTime() - (newDate?.getTimezoneOffset() * 60000));
                  newValue = newDate.toISOString().substring(0, 10);
              }

              if (currentValue === newValue) {
                  // values are the same
                  return;
              }

              helpers.setValue(newDate);
          }}
    />
  );
};
