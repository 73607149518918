import React from 'react';
import PasswordPolicyProvider from '../passwordPolicy/PasswordPolicyProvider';
import ResetPasswordPage from './ResetPasswordPage';

const ResetPasswordPageContainer: React.FC = () => (
  <PasswordPolicyProvider
    minimumLength={10}
    minimumLetters={2}
    minimumNumbers={1}
    minimumUppercase={1}
    minimumLowercase={1}
    minimumSpecial={1}
    notStartOrEndWithASpace
    notContainAnySpaces
  >
    <ResetPasswordPage />
  </PasswordPolicyProvider>
)

export default ResetPasswordPageContainer;
