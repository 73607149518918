import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { ILoginPageFormValues, ILoginResult } from './types';
import ErrorList from '../errorList';
import DataProtectionAgreementDialog from '../dataProtectionAgreement';
import { FormGroup } from '../forms/formGroup';
import { TextBox } from '../formikBindings';
import { ActionButton } from '../forms';
import schema from './schema';
import attemptLogin from './attemptLogin';
import Logo from '../../images/IAMRoadSmartLogo.jpg';
import styles from './LoginPage.module.css';
import useAuthentication from '../authentication';

const initialValues: ILoginPageFormValues = {
  userName: '',
  password: '',
};

const LoginPage: React.FC = () => {
  const { onLoggedIn } = useAuthentication();

  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
  const [loginResult, setLoginResult] = useState<ILoginResult>(
    {} as ILoginResult,
  );
  const [showDialog, setShowDialog] = useState<boolean>(false);

  return (
    <div className="container">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting }) => {
          setAjaxErrors([]);
          attemptLogin(values)
            .then(response => {
              setLoginResult(response.data);
              setShowDialog(true);
            })
            .catch((errors: Array<string>) => {
              setAjaxErrors(errors);
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, setFieldValue, setFieldTouched }) => (
          <div className={styles.container}>
            <div className="row">
              <div className="col-lg-6">
                <div className={styles.imageContainer}>
                  <img className={styles.logo} src={Logo} alt="IAM RoadSmart" />
                </div>
              </div>
              <div className="col-lg-6">
                <Form>
                  <FormGroup name="userName" label="Username">
                    <TextBox name="userName" />
                  </FormGroup>

                  <FormGroup name="password" label="Password">
                    <TextBox name="password" type="password" />
                  </FormGroup>

                  <ErrorList errors={ajaxErrors} className="mb-3" />

                  <div className={styles.buttonContainer}>
                    <Link to="/forgottenPassword">Forgotten password?</Link>
                    <ActionButton busy={isSubmitting} type="submit" isPrimary>
                      Login
                    </ActionButton>
                  </div>
                </Form>
              </div>
            </div>

            <DataProtectionAgreementDialog
              open={showDialog}
              onClickContinue={() => onLoggedIn(loginResult)}
              onClickCancel={() => {
                setFieldValue('password', '');
                setFieldTouched('password', false);
                setShowDialog(false);
              }}
            />
          </div>
        )}
      </Formik>
    </div>
  );
};

export default LoginPage;
