import { IPasswordPolicyRule } from './types';
import * as validators from './passwordPolicyValidationRules';

const injectValueAndPluralise = (template: string, value: number): string =>
  template
    .replace('{0}', value.toString())
    .replace('{1}', value > 1 ? 's' : '');

const createPolicyRules = (
  minimumLength: number,
  minimumLetters: number,
  minimumNumbers: number,
  minimumUppercase: number,
  minimumLowercase: number,
  minimumSpecial: number,
  notStartOrEndWithASpace: boolean,
  notContainAnySpaces: boolean,
): Array<IPasswordPolicyRule> => {
  const rules = [];

  if (minimumLength > 0) {
    rules.push({
      text: injectValueAndPluralise(
        'Be at least {0} character{1}',
        minimumLength,
      ),
      validate: validators.hasMinimumLength(minimumLength),
    });
  }

  if (minimumLetters > 0) {
    rules.push({
      text: injectValueAndPluralise(
        'Contain at least {0} letter{1}',
        minimumLetters,
      ),
      validate: validators.hasMinimumAlphaCharacters(minimumLetters),
    });
  }

  if (minimumNumbers > 0) {
    rules.push({
      text: injectValueAndPluralise(
        'Contain at least {0} number{1}',
        minimumNumbers,
      ),
      validate: validators.hasMinimumNumericCharacters(minimumNumbers),
    });
  }

  if (minimumUppercase > 0) {
    rules.push({
      text: injectValueAndPluralise(
        'Include at least {0} uppercase letter{1}',
        minimumUppercase,
      ),
      validate: validators.hasMinimumUppercaseLetters(minimumUppercase),
    });
  }

  if (minimumLowercase > 0) {
    rules.push({
      text: injectValueAndPluralise(
        'Include least {0} lowercase letter{1}',
        minimumLowercase,
      ),
      validate: validators.hasMinimumLowercaseLetters(minimumLowercase),
    });
  }

  if (minimumSpecial > 0) {
    rules.push({
      text: injectValueAndPluralise(
        'Include least {0} special character{1} (e.g. !#_$%&()*+%)',
        minimumSpecial,
      ),
      validate: validators.hasMinimumSpecialCharacters(minimumSpecial),
    });
  }

  if (notStartOrEndWithASpace) {
    rules.push({
      text: 'Not start or end with a space',
      validate: validators.doesNotStartOrEndWithSpaceCharacter,
    });
  }

  if (notContainAnySpaces) {
    rules.push({
      text: 'Not contain a space',
      validate: validators.doesNotContainSpaceCharacter,
    });
  }

  return rules;
};

export default createPolicyRules;
