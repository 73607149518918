// https://reactjs.org/blog/2015/12/16/ismounted-antipattern.html
export interface CancelablePromise {
  promise: Promise<any>;
  cancel: () => void;
}

export default function makeCancelable(
  promise: Promise<any>,
  source: any,
): CancelablePromise {
  let hasCanceled_ = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled_ ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled_ ? reject({ isCanceled: true }) : reject(error)),
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled_ = true;
      if (source) {
        source.cancel();
      }
    },
  };
}
