import React from 'react';
import { useField } from 'formik';
import { NumericTextBoxModel } from '@syncfusion/ej2-inputs';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { IFormikFieldProps } from './types';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';

export type NumericTextBoxProps = IFormikFieldProps &
  NumericTextBoxModel &
  DefaultHtmlAttributes;

export const NumericTextBox: React.FC<NumericTextBoxProps> = (props) => {
  const [field] = useField(props);

  return <NumericTextBoxComponent immediateRender {...field} {...props} />;
};
