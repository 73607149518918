import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import AjaxErrorBoundary from '../../../../../ajax/errorBoundary';
import { FormGroup, ActionButton } from '../../../../../forms';
import { DropDownList } from '../../../../../formikBindings';
import Modal from 'react-modal';
import ErrorList from '../../../../../errorList';
import { useDataFetcher } from '../../../../../hooks';
import toSyncfusionDataSource from '../../../../../../utils/toSyncfusionDataSource';
import { DropDownItem } from '../../../../../types';
import { ButtonComponent } from '@syncfusion/ej2-react-buttons';
import { IAddLinkModalProps, IAddLinkModalFormValues } from './types';
import createGroupMemberLink from './createGroupMemberLink';
import schema from './schema';
import useUserData from '../../../../../userData';

const transformDropDownData = (data: {
  items: Array<DropDownItem>;
}): Array<{ [key: string]: Object }> => toSyncfusionDataSource(data.items);

const config = {
  transformer: transformDropDownData,
};

const initialValues: IAddLinkModalFormValues = {
  groupRoleDescId: '',
};

export const AddLinkModal: React.FC<IAddLinkModalProps> = ({
  organisationFileId,
  contactFileId,
  onAdded,
  onCancel,
}) => {
  const { getSelectedOrganisationName } = useUserData();

  const { busy, data, errors } = useDataFetcher('/api/groupRoles', config);
  const [ajaxErrors, setAjaxErrors] = useState<Array<string>>([]);
  const [isAdding, setIsAdding] = useState<boolean>(false);

  return (
    <Modal isOpen className="model-content" overlayClassName="modal-overlay">
      <div>
        <h1 className="text-center">Add Group Member Link</h1>
        <AjaxErrorBoundary
          busy={busy}
          errors={errors}
          loadingText="Loading link types"
        >
          <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={(values: IAddLinkModalFormValues) => {
              setAjaxErrors([]);
              setIsAdding(true);

              createGroupMemberLink({
                contactFileId,
                organisationFileId,
                groupRoleDescId: +values.groupRoleDescId,
              })
                .then(() => {
                  // get organisation name
                  const organisationName = getSelectedOrganisationName();

                  // get role description
                  const roleDescription = data.find(
                    (entry: any) => entry.id === values.groupRoleDescId,
                  ).item.displayValue;

                  onAdded();
                })
                .catch(setAjaxErrors)
                .finally(() => setIsAdding(false));
            }}
          >
            <Form>
              <ErrorList errors={ajaxErrors} />
              <FormGroup label="Link Types" name="groupRoleDescId">
                <DropDownList name="groupRoleDescId" dataSource={data} />
              </FormGroup>
              <div className="d-flex justify-content-between mt-3">
                <ButtonComponent type="button" onClick={onCancel}>
                  Cancel
                </ButtonComponent>
                <ActionButton
                  busy={isAdding}
                  type="submit"
                  cssClass="mr-2 e-primary"
                >
                  Continue
                </ActionButton>
              </div>
            </Form>
          </Formik>
        </AjaxErrorBoundary>
      </div>
    </Modal>
  );
};
