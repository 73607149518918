// EU1832 - TO - 12/09/2022
export const AddId: string = 'AddId';
export const DeleteId: string = 'DeleteId';

export const ToolbarDescriptions: { [key: string]: string } = {
  AddId: 'Add',
  DeleteId: 'Delete'
};

export const GroupLinksToolbar: any = [
  {
    text: ToolbarDescriptions[AddId],
    tooltipText: ToolbarDescriptions[AddId],
    id: AddId
  },
  {
    text: ToolbarDescriptions[DeleteId],
    tooltipText: ToolbarDescriptions[DeleteId],
    id: DeleteId
  }
];
