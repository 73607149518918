import React from 'react';
import { FormGroup } from '../../../forms';
import { CheckBox, TextBox, DropDownList } from '../../../formikBindings';
import { IAddressTabProps } from './types';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { ChangeEventArgs } from '@syncfusion/ej2-dropdowns';
import { fields } from '../../../../utils/toSyncfusionDataSource';

const AddressTab: React.FC<IAddressTabProps> = ({
    addressTypes,
    selectedAddressIndex,
    onChangeSelectedAddressIndex,
    counties
}) => {
    const handleAddressTypeChange = (args: ChangeEventArgs | undefined) => {
        if (typeof args === 'undefined' || !args.e) return;

        onChangeSelectedAddressIndex(+args.value);
    };

    return (
        <div>

            <FormGroup
                label="Property"
                name={`addresses[${selectedAddressIndex}].premise`}
            >
                <TextBox name={`addresses[${selectedAddressIndex}].premise`} disabled={true} />
            </FormGroup>
            <FormGroup
                label="Street 1"
                name={`addresses[${selectedAddressIndex}].street`}
            >
                <TextBox name={`addresses[${selectedAddressIndex}].street`} disabled={true} />
            </FormGroup>
            <FormGroup
                label="Street 2"
                name={`addresses[${selectedAddressIndex}].district`}
            >
                <TextBox name={`addresses[${selectedAddressIndex}].district`} disabled={true} />
            </FormGroup>
            <FormGroup label="District/Town" name={`addresses[${selectedAddressIndex}].town`}>
                <TextBox name={`addresses[${selectedAddressIndex}].town`} disabled={true} />
            </FormGroup>
            <FormGroup
                label="County"
                name={`addresses[${selectedAddressIndex}].county`}
            >
                <DropDownList name={`addresses[${selectedAddressIndex}].county`} dataSource={counties} enabled={false} />
            </FormGroup>
            <FormGroup
                label="Postcode"
                name={`addresses[${selectedAddressIndex}].postcode`}
            >
                <TextBox name={`addresses[${selectedAddressIndex}].postcode`} disabled={true} />
            </FormGroup>
            <div className="form-row">
                <div className="col-md-6">
                    <FormGroup
                        label="Telephone 1"
                        name={`addresses[${selectedAddressIndex}].telephone1`}
                    >
                        <TextBox
                            name={`addresses[${selectedAddressIndex}].telephone1`}
                            type="tel"
                        />
                    </FormGroup>
                </div>
                <div className="col-md-6">
                    <FormGroup
                        label="Telephone 2"
                        name={`addresses[${selectedAddressIndex}].telephone2`}
                    >
                        <TextBox
                            name={`addresses[${selectedAddressIndex}].telephone2`}
                            type="tel"
                        />
                    </FormGroup>
                </div>
            </div>

            <FormGroup
                label="Email"
                name={`addresses[${selectedAddressIndex}].email`}
            >
                <TextBox name={`addresses[${selectedAddressIndex}].email`} />
            </FormGroup>

            <FormGroup
                label="Mobile"
                name={`addresses[${selectedAddressIndex}].mobile`}
            >
                <TextBox name={`addresses[${selectedAddressIndex}].mobile`} />
            </FormGroup>
        </div>
    );
};

export default AddressTab;
