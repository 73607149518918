import { useContext } from 'react';
import { IUserDataContext } from './types';
import UserDataContext from './UserDataContext';

const useUserData = (): IUserDataContext => {
  const context = useContext(UserDataContext);

  if (!context) {
    throw new Error('This component must be a <UserDataProvider> component');
  }

  return context;
};

export default useUserData;
