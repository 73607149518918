import React from 'react';
import { useFormikContext } from 'formik';

export const SubmitButton: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { isSubmitting, submitForm } = useFormikContext();

    const handleClick = () => {
        if (isSubmitting) return;

        submitForm();
    };

    return (
        <button
            type="button"
            className="e-control e-btn e-primary"
            onClick={handleClick}
        >
            {isSubmitting && <i className="fas fa-spinner fa-spin mr-2" />}
            {children}
        </button>
    );
};
