import React, { useMemo } from 'react';
import { IEditLocalGroupMemberDetailsPageProps } from './types';
import AjaxErrorBoundary from '../../../ajax/errorBoundary';
import LocalGroupMemberDetailsForm from '../form';
import { useDataFetcher, useBatchDataFetcher } from '../../../hooks';
import updateLocalGroupMemberDetails from './updateLocalGroupMemberDetails';
import { DropDownItem } from '../../../types';
import toSyncfusionDataSource from '../../../../utils/toSyncfusionDataSource';

const transformDropDownData = (data: {
    items: Array<DropDownItem>;
}): Array<{ [key: string]: Object }> => toSyncfusionDataSource(data.items);

const config = {
    transformers: [(countiesData: any) => transformDropDownData(countiesData)],
};

const EditLocalGroupMemberDetailsPage: React.FC<IEditLocalGroupMemberDetailsPageProps> = ({
    fileId,
    groupId,
    onClose,
    forNotification
}) => {

    const urls = useMemo(() => {
        if (forNotification) {
            return ['/api/counties', `/api/localGroupMemberDetails/${groupId}/${fileId}?forNotification=true`, `/api/myDetails/${groupId}/officer`]
        } else {
            return ['/api/counties', `/api/localGroupMemberDetails/${groupId}/${fileId}`, `/api/myDetails/${groupId}/officer`]
        }
    },
        [groupId, fileId]);

    const {
        busy,
        data: [counties, data, isGroupOfficer],
        errors,
    } = useBatchDataFetcher(urls, config);

  return (
    <AjaxErrorBoundary
      loadingText="Loading Member Details"
      busy={busy}
      errors={errors}
    >
      <LocalGroupMemberDetailsForm
              fileId={fileId}
              groupId={groupId}
              initialValues={data}
              promise={updateLocalGroupMemberDetails}
              onSubmit={() => { }}
              onClose={onClose}
              counties={counties}
              isGroupOfficer={isGroupOfficer}
      />
    </AjaxErrorBoundary>
  );
};

export default EditLocalGroupMemberDetailsPage;
