import React from 'react';
import { ErrorMessage } from 'formik';
import { IFormGroupProps } from './types';
import styles from './FormGroup.module.css';

export const FormGroup: React.FC<IFormGroupProps> = ({
  className,
  iconClassName,
  label,
  name,
  required,
  children,
}) => {
  return (
    <div className={`form-group ${className ? className : ''}`}>
      {iconClassName && <i className={`${iconClassName} mr-1`} />}
      {label && (
        <label htmlFor={name} className={required ? styles.required : ''}>
          {label}
        </label>
      )}
      {children}
      <ErrorMessage className="text-danger" component="div" name={name} />
    </div>
  );
};
