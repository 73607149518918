import React from 'react';
import PasswordPolicyProvider from '../passwordPolicy/PasswordPolicyProvider';
import ResetCurrentPasswordPage from './ResetCurrentPasswordPage';

const ResetCurrentPasswordPageContainer: React.FC = () => (
  <PasswordPolicyProvider
    minimumLength={10}
    minimumLetters={2}
    minimumNumbers={1}
    minimumUppercase={1}
    minimumLowercase={1}
    minimumSpecial={1}
    notStartOrEndWithASpace
    notContainAnySpaces
  >
    <ResetCurrentPasswordPage />
  </PasswordPolicyProvider>
);

export default ResetCurrentPasswordPageContainer;
