export const hasMinimumLength = (minimumLength: number) => (
  value: string,
): boolean => value.length >= minimumLength;

export const hasMinimumAlphaCharacters = (numberOfAlphaCharacters: number) => (
  value: string,
): boolean => value.replace(/\d/g, '').length >= numberOfAlphaCharacters;

export const hasMinimumNumericCharacters = (
  numberOfNumericCharacters: number,
) => (value: string): boolean =>
  value.replace(/\D/g, '').length >= numberOfNumericCharacters;

export const hasMinimumUppercaseLetters = (
  numberOfUppercaseCharacters: number,
) => (value: string): boolean =>
  value.replace(/[^A-Z]/g, '').length >= numberOfUppercaseCharacters;

export const hasMinimumLowercaseLetters = (
  numberOfLowercaseCharacters: number,
) => (value: string): boolean =>
  value.replace(/[^a-z]/g, '').length >= numberOfLowercaseCharacters;

export const hasMinimumSpecialCharacters = (
  numberOfSpecialCharacters: number,
) => (value: string): boolean =>
  value.replace(/[a-zA-Z0-9]/g, '').length >= numberOfSpecialCharacters;

export const doesNotStartOrEndWithSpaceCharacter = (value: string): boolean =>
  value.length > 0 && !(value.startsWith(' ') || value.endsWith(' '));

export const doesNotContainSpaceCharacter = (value: string): boolean =>
  value.length > 0 && value.indexOf(' ') === -1;
