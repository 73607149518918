import { AxiosPromise } from 'axios';
import { IUpdateResultsCommand } from './types';
import { instance } from '../../../services/api';

export default (data: IUpdateResultsCommand): AxiosPromise => {
  const { id, token, results, complete } = data;
  const config = {
    headers: {
      'x-auth': token,
    },
  };

  return instance.put(
    `api/driverEvaluations/${id}/moderator`,
    { results, complete },
    config,
  );
};
