import { instance } from '../../../services/api';
import { IRaiseMemberQueryCommand } from './types';

export default (command: IRaiseMemberQueryCommand) => {
  const { fileId, ...rest } = command;

  return instance.post(`api/localGroupMemberDetails/${fileId}/queries`, {
    ...rest,
  });
};
