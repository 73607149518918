import React from 'react';
import { QuestionTypes } from '../../../../constants';
import {
    CheckAnswer,
    DateTimeAnswer,
    IdentifiedTargetedCompletedSourceAnswer,
    NumberAnswer,
    ScoreAnswer,
    TextAnswer,
    TextReadOnlyAnswer,
    MultiLineTextAnswer,
} from './answers';
import { FormGroup } from '../../../forms';
import { IDriverEvaluationReportQuestion } from '../types';
import { useState } from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

const ANSWER_COMPONENTS: { [key: number]: any } = {
    0: TextReadOnlyAnswer,
    [QuestionTypes.Check]: CheckAnswer,
    [QuestionTypes.DateTime]: DateTimeAnswer,
    [QuestionTypes.IdentifiedTargetedCompletedSource]: IdentifiedTargetedCompletedSourceAnswer,
    [QuestionTypes.Number]: NumberAnswer,
    [QuestionTypes.Score]: ScoreAnswer,
    [QuestionTypes.Text]: TextAnswer,
    [QuestionTypes.TextReadonly]: TextReadOnlyAnswer,
    [QuestionTypes.TextMomo]: MultiLineTextAnswer
};

const ShouldLabelBeDisplayedFor = (questionType: number) => {
    return questionType !== QuestionTypes.Check;
};

const Question: React.FC<IDriverEvaluationReportQuestion> = ({
    fileId,
    type,
    text,
    isRequired,
    isResultMaster,
    restrictions,
    enablestatus,
    needsCheckBoxToActivate
}) => {
    const SpecificAnswer = ANSWER_COMPONENTS[type];

    const [enabled, setEnabled] = useState<boolean>(false);

    return (
        <FormGroup
            className={isResultMaster ? 'result-master' : undefined}
            iconClassName={isResultMaster ? 'fas fa-star' : undefined}
            name={fileId.toString()}
            label={ShouldLabelBeDisplayedFor(type) ? text : undefined}
            required={isRequired || isResultMaster}
        >
            {needsCheckBoxToActivate ? (
                <div>
                    <div className="mb-2">
                        <CheckBoxComponent
                            label="Tick to enable"
                            change={e => {
                                if (typeof (e) !== 'undefined') {
                                    setEnabled(e.checked ?? enabled);
                                }
                            }}
                        />
                    </div>
                    <SpecificAnswer
                        name={fileId.toString()}
                        label={text}
                        enablestatus={enabled}
                        restrictions={restrictions}
                        className="mt-5"
                    />
                </div>
            ) : (
                <SpecificAnswer
                    name={fileId.toString()}
                    label={text}
                    enablestatus={enablestatus}
                    restrictions={restrictions}
                />
            )}
        </FormGroup>
    );
};

export default Question;
