import React from 'react';
import { IBusyIndicatorProps } from './types';
import styles from './BusyIndicator.module.css';

const BusyIndicator: React.FC<IBusyIndicatorProps> = ({ text, fullScreen }) => {
  return (
    <div className={`${styles.container} ${fullScreen && styles.fullScreen}`}>
      <i className="fas fa-spinner fa-spin fa-3x" />
      <span className={styles.text}>{text}...</span>
    </div>
  );
};

BusyIndicator.defaultProps = {
  fullScreen: false,
};

export default BusyIndicator;
