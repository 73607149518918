import React, { useRef, useLayoutEffect } from 'react';
import { useField } from 'formik';
import { TextBoxModel } from '@syncfusion/ej2-inputs';
import { DefaultHtmlAttributes } from '@syncfusion/ej2-react-base';
import { IFormikFieldProps } from './types';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';

export type TextBoxProps = IFormikFieldProps &
  TextBoxModel &
  DefaultHtmlAttributes;

export const TextBox: React.FC<TextBoxProps> = props => {
  const [field] = useField(props);

  return <TextBoxComponent immediateRender {...field} {...props} />;
};

export type MultilineTextBoxProps = IFormikFieldProps &
    TextBoxModel &
    DefaultHtmlAttributes & { rows?: number };

export const MultilineTextBox: React.FC<MultilineTextBoxProps> = props => {
    const [field] = useField(props);
    const { rows } = props;
    const element = useRef<TextBoxComponent>(null);

    useLayoutEffect(() => {
        if (element?.current && rows) {
            element.current.addAttributes({ 'rows': rows.toString() });
        }
    }, [rows]);

    return (
        <TextBoxComponent
            ref={element}
            immediateRender {...field} {...props}
            multiline
        />   
   );
};